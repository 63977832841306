import React, {useCallback, useEffect, useState} from 'react'
import {Button, Grid, LegacyCard, Spinner, Toast, Frame} from "@shopify/polaris";
import { ReactComponent as EmptyCircle } from "../assets/image/even.svg"
import { ReactComponent as GreenCheck } from "../assets/image/green-checkbox.svg"

import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {apiEndpoint} from "../helper/config";
import {updateData,updateOnBoardingStatus} from "../store/slices/storeSlices";
function OnBoarding() {
    const dispatch = useDispatch();
    const shop_data = useSelector((state) => {
        return state.clientStore
    });
    const on_boarding_status = shop_data?.sdata?.shop_info?.onboarding_status
    const [onBoardingSteps,setOnBoardingSteps]  = useState({"enable_app":0,"enable_cod_to_prepaid":0,"enable_abandoned_cart":0})

    const [codPrepaidData, setCodPrepaidData] = useState({});
    const [abdData, setAbdData] = useState({});

    let default_button_state = {enable_app_btn_status:false,cod_to_prepaid_btn_status:true,abandoned_cart_btn_status:true};
    let loading_button_state = {enable_app_btn_status:false,cod_to_prepaid_btn_status:false,abandoned_cart_btn_status:false};

    const [loading,setLoading] = useState(loading_button_state);
    const [isDisabled,setIsDisabled] = useState(default_button_state);
    const [abandonedCartStatus, setAbandonedCartStatus] = useState('0');

    const [error, setError] = useState(false);
    const [active, setActive] = useState(false);
    const [toastMsg,setToastMsg] = useState("Message sent")

    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const toastMarkup = active ? (<Toast content={toastMsg} onDismiss={toggleActive} error={error}/>) : null;

    useEffect(() => {
        console.log("on_boarding_status=================>",on_boarding_status)
        if(on_boarding_status == '1'){

            setOnBoardingSteps({...onBoardingSteps, enable_app: 1,enable_abandoned_cart: 0,enable_cod_to_prepaid:0})
            setIsDisabled({...isDisabled, cod_to_prepaid_btn_status: false});
        }
        else if(on_boarding_status == '2'){

            setOnBoardingSteps({...onBoardingSteps, enable_app: 1,enable_cod_to_prepaid:1, enable_abandoned_cart: 0})
            setIsDisabled({...isDisabled, abandoned_cart_btn_status: false});
        }else if(on_boarding_status == '3'){

            setOnBoardingSteps({...onBoardingSteps,enable_app: 1,enable_cod_to_prepaid:1, enable_abandoned_cart: 1})
        } else {

            setOnBoardingSteps({...onBoardingSteps,enable_app:0,enable_cod_to_prepaid:0, enable_abandoned_cart: 0})
            setIsDisabled({...isDisabled, enable_app_btn_status:false,cod_to_prepaid_btn_status:true,abandoned_cart_btn_status:true});
        }
    }, [on_boarding_status]);

    const getCodPrepaidData = async ()=>{
        try {
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            let send_data = {
                shop_info:shop_data.sdata.shop_info,
                scid: shop_data?.sdata?.store_client_id
            };
            const response = await axios.post(apiEndpoint + `/api/get-wa-cod-prepaid-template`, send_data,{headers: headers})

            let response_data = response?.data?.data;
            setCodPrepaidData(response_data);


        } catch (error) {
            setToastMsg("Something went wrong")
            setError(true)
            toggleActive()
        }
    }

    const handleEnableBtn = ()=>{
        setLoading({...loading, enable_app_btn_status: true});
        setIsDisabled({...isDisabled, enable_app_btn_status: true});
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/enable-app',{shop_info: shop_data.sdata.shop_info,status:true},{
            headers: headers
        }).then((response) => {
            if(response.data.status == 200) {
                let status = (shop_data?.sdata?.shop_info?.app_status == '0') ? '1' : '0'
                dispatch(updateData(status));
                dispatch(updateOnBoardingStatus('1'));

                setLoading({...loading, enable_app_btn_status: false});

            }
        }).catch((error)=>{
            setLoading({...loading, enable_app_btn_status: false});
            setIsDisabled({...isDisabled, enable_app_btn_status: false});
        })
    }

    const handleCODPrepaidEnableBtn = ()=> {
        let button_status=true;
        setLoading({...loading, cod_to_prepaid_btn_status: button_status});
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }

        let status = (codPrepaidData?.template_status == '0') ? '1' : '0';
        let data = {shop_info: shop_data.sdata.shop_info,prepaid_id:codPrepaidData?.id,prepaid_status:status,status:true};

        axios.post(apiEndpoint+ '/api/enable-wa-cod-prepaid',data,{
            headers: headers
        }).then((response) => {
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved");
                setCodPrepaidData({...codPrepaidData, template_status: status});
                setLoading({...loading, cod_to_prepaid_btn_status: !button_status});
                dispatch(updateOnBoardingStatus('2'));
                toggleActive();
            }else{
                setToastMsg("Something went wrong")
                setError(true);
                setLoading({...loading, cod_to_prepaid_btn_status: !button_status});
            }
        }).catch((error)=>{
            setLoading({...loading, cod_to_prepaid_btn_status: !button_status});
            setToastMsg("Something went wrong");
            setError(true);
            toggleActive();
        })
    }

    const handleABDCartEnableBtn = () => {

        setLoading({...loading, abandoned_cart_btn_status: true});
        setIsDisabled({...isDisabled, abandoned_cart_btn_status: true});

        let status = (abandonedCartStatus == '0') ? '1' : '0';
        let data = {
            adc_id:abdData?.id,
            abandoned_cart_status:status
        };

        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint + '/api/enable-abandoned-cart', {shop_info: shop_data?.sdata?.shop_info,status:true,data},{
            headers: headers
        }).then((response) => {

            if (response?.data?.status == 200) {
                setToastMsg("Successfully Saved");
                setAbdData({...abdData,template_status:status});
                dispatch(updateOnBoardingStatus('3'));
            } else{
                setToastMsg("Something went wrong")
                setError(true)
            }
        }).catch((error) => {
            setToastMsg("Something went wrong")
            setLoading({...loading, abandoned_cart_btn_status: false});
            setIsDisabled({...isDisabled, abandoned_cart_btn_status: false});
            setError(true)
        }).finally(() => {
            setLoading({...loading, abandoned_cart_btn_status: false});
            setIsDisabled({...isDisabled, abandoned_cart_btn_status: false});
            toggleActive()
        });
    }

    const getABDCartGeneralSettings = ()=>{

        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/get-abandoned-cart-settings',{shop_info: shop_data.sdata.shop_info},{
            headers: headers
        }).then((response) => {

            if(response?.data?.status == 200) {
                let res_data = response.data.data;
                console.log("res_data=============getABDCartGeneralSettings===============>",res_data)
                setAbdData(res_data)
                setAbandonedCartStatus(res_data?.template_status)

            } else{
                setToastMsg("Something went wrong")
                setError(true)
                toggleActive()
            }

        }).catch((error)=>{
            console.log("error================getABDCartGeneralSettings==================>",error)
            setToastMsg("Something went wrong")
            setError(true)
            toggleActive()
        })
    }
    useEffect(() => {
        getCodPrepaidData();
        getABDCartGeneralSettings();
    },[]);
    return (
        <>
            <Frame>
            <div className="Polaris-Page" style={{paddingTop:25, paddingBottom:25}}>

            <Grid>
                <Grid.Cell columnSpan={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                    <LegacyCard title="Improve your conversion rate" sectioned>

                        <h2 className="Polaris-Text--root Polaris-Text--bodyMd">Increase the percentage of visitors who
                            purchase something from your online store.</h2>
                        <div className="" style={{display: 'flex',alignItems: 'baseline',marginBottom:25}}>
                            <p style={{marginTop:15,width: 220}}>{on_boarding_status} of 3 tasks complete</p>
                            <div style={{width:"100%"}}>
                                <div
                                    className="Polaris-ProgressBar Polaris-ProgressBar--sizeSmall Polaris-ProgressBar--colorHighlight">
                                    <progress className="Polaris-ProgressBar__Progress" value="40" max="100">
                                    </progress>
                                    <div
                                        className="Polaris-ProgressBar__Indicator Polaris-ProgressBar__IndicatorAppearActive"
                                        style={{"--pc-progress-bar-duration":"500ms","--pc-progress-bar-percent":((on_boarding_status == '1') ? 0.3 : (on_boarding_status == '2') ? 0.6 : (on_boarding_status == '3')? 1: 0)}}>
                                        <span className="Polaris-ProgressBar__Label">{((on_boarding_status == '1') ? '33%' : (on_boarding_status == '2') ? '66%' : (on_boarding_status == '3') ? '100%':'0')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LegacyCard>
                </Grid.Cell>
                <Grid.Cell columnSpan={{xs: 4, sm: 3, md: 3, lg: 4, xl: 4}}>
                    <LegacyCard  sectioned>
                        <div className="initsat-accordion-header">
                            {(onBoardingSteps.enable_app == 0) ? <span style={{marginRight: 10}}><EmptyCircle/></span> :
                                <span style={{marginRight: 10}}><GreenCheck/></span> }
                            Enable App
                        </div>
                         <div className="initsat-accordion-content">
                            <p style={{marginBottom: 10}}>Enable App</p>

                             {(shop_data?.sdata?.shop_info?.app_status === '0') ? <button className="Polaris-Button Polaris-Button--primary" type="button" onClick={handleEnableBtn}>
                                  <span className="Polaris-Button__Content">
                                    <span className="Polaris-Button__Text" id="enable_app">{(loading.enable_app_btn_status) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.enable_app_btn_status}/> : 'Enable App'}</span>
                                  </span>
                            </button>:
                                 <Button destructive onClick={handleEnableBtn} disabled>{(loading.enable_app_btn_status) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.enable_app_btn_status}/> : 'Enabled App'} </Button> }
                        </div>
                    </LegacyCard>
                </Grid.Cell>

                <Grid.Cell columnSpan={{xs: 4, sm: 3, md: 3, lg: 4, xl: 4}}>
                    <LegacyCard sectioned>
                        <div className="initsat-accordion-header">
                            {(onBoardingSteps.enable_cod_to_prepaid == 0) ?<span style={{marginRight: 10}}><EmptyCircle/></span> :
                                <span style={{marginRight: 10}}><GreenCheck/></span> }
                            COD To Prepaid
                        </div>
                        <div className="initsat-accordion-content">
                            <p style={{marginBottom:10}}>COD To Prepaid</p>
                            {(codPrepaidData?.template_status === '0') ?


                            // <button className="Polaris-Button Polaris-Button--primary" type="button" onClick={handleCODPrepaidEnableBtn} disabled={isDisabled.cod_to_prepaid_btn_status}>
                            //       <span className="Polaris-Button__Content">
                            //         <span className="Polaris-Button__Text"  id="enable_abandoned_cart">{(loading.cod_to_prepaid_btn_status) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.cod_to_prepaid_btn_status}/> : 'Enable'}</span>
                            //       </span>
                            // </button>
                                <Button primary  onClick={handleCODPrepaidEnableBtn} disabled={(isDisabled.cod_to_prepaid_btn_status)}>
                                    {(loading.cod_to_prepaid_btn_status) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.cod_to_prepaid_btn_status}/> : 'Enable'}
                                </Button> :
                                <Button destructive onClick={handleCODPrepaidEnableBtn} disabled>{(loading.cod_to_prepaid_btn_status) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.cod_to_prepaid_btn_status}/> : 'Enabled'} </Button> }
                        </div>
                    </LegacyCard>
                </Grid.Cell>

                <Grid.Cell columnSpan={{xs: 4, sm: 3, md: 3, lg: 4, xl: 4}}>
                    <LegacyCard sectioned>
                        <div className="initsat-accordion-header">
                            {(onBoardingSteps.enable_abandoned_cart == 0) ?
                                <span style={{marginRight: 10}}><EmptyCircle/></span> :
                                <span style={{marginRight: 10}}><GreenCheck/></span> }
                            Enable Abandoned cart
                        </div>
                        <div className="initsat-accordion-content">
                            <p style={{marginBottom:10}}>Abandoned cart</p>
                            {(abdData?.template_status === '0') ?
                                // <button className="Polaris-Button Polaris-Button--primary" type="button" onClick={handleABDCartEnableBtn}>
                                //   <span className="Polaris-Button__Content">
                                //     <span className="Polaris-Button__Text"  id="enable_abandoned_cart">Enable</span>
                                //   </span>
                                // </button>
                                <Button primary  onClick={handleABDCartEnableBtn} disabled={(isDisabled.abandoned_cart_btn_status)}>
                                    {(loading.abandoned_cart_btn_status) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.abandoned_cart_btn_status}/> : 'Enable'}
                                </Button> :
                                <Button destructive onClick={handleABDCartEnableBtn} disabled>{(loading.abandoned_cart_btn_status) ?
                                    <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.abandoned_cart_btn_status}/> : 'Enabled'}
                                </Button> }
                        </div>
                    </LegacyCard>
                </Grid.Cell>

                {toastMarkup}
            </Grid>
            </div>
            </Frame>
        </>
    );
}


// <div className="Polaris-Page">
//     <div className="Polaris-Box" style={{background: "#fff",
//         padding: 20,
//         borderRadius: 5,
//         marginBottom: 20}}>
//         <h2 className="Polaris-Text--root Polaris-Text--bodyMd"><b>Improve your conversion rate</b></h2>
//         <h2 className="Polaris-Text--root Polaris-Text--bodyMd">Increase the percentage of visitors who
//             purchase something from your online store.</h2>
//         <div className="" style={{display: 'flex',alignItems: 'baseline',marginBottom:25}}>
//             <p style={{marginTop:15,width: 220}}>{onBoardingStatus} of 2 tasks complete</p>
//             <div style={{width:"100%"}}>
//                 <div
//                     className="Polaris-ProgressBar Polaris-ProgressBar--sizeSmall Polaris-ProgressBar--colorHighlight">
//                     <progress className="Polaris-ProgressBar__Progress" value="40" max="100">
//                     </progress>
//                     <div
//                         className="Polaris-ProgressBar__Indicator Polaris-ProgressBar__IndicatorAppearActive"
//                         style={{"--pc-progress-bar-duration":"500ms","--pc-progress-bar-percent":((onBoardingStatus == '1') ? 0.5 : (onBoardingStatus == '0') ? 0 : 1)}}>
//                         <span className="Polaris-ProgressBar__Label">{((onBoardingStatus == '1') ? '50%' : (onBoardingStatus == '0') ? '0%' : '100%')}</span>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <hr className="Polaris-Divider"
//             style={{"border-block-start":"var(--p-border-width-1) solid var(--p-color-border-subdued)", paddingBottom:10}}/>
//
//         <div className="accordion">
//             <div className="initsat-accordion-item" onClick={handleEnableApp}>
//                 <div className="initsat-accordion-header">
//                     {(onBoardingSteps.enable_app == 0) ? <span style={{marginRight: 10}}><EmptyCircle/></span> :
//                         <span style={{marginRight: 10}}><GreenCheck/></span> }
//                     Enable App
//                 </div>
//                 {(enableApp && onBoardingStatus != '2') ? <div className="initsat-accordion-content">
//                     <p style={{marginBottom: 10}}>Enable App</p>
//                     <button className="Polaris-Button Polaris-Button--primary" type="button">
//                                   <span className="Polaris-Button__Content">
//                                     <span className="Polaris-Button__Text" id="enable_app"><Link to={`/configure?shop=${shop}`}>Enable App</Link></span>
//                                   </span>
//                     </button>
//                 </div> : ''}
//             </div>
//             <div className="initsat-accordion-item" onClick={handleEnableAbandonedCart}>
//                 <div className="initsat-accordion-header">
//                     {(onBoardingSteps.enable_abandoned_cart == 0) ?<span style={{marginRight: 10}}><EmptyCircle/></span> :
//                         <span style={{marginRight: 10}}><GreenCheck/></span> }
//                     Enable Abandoned cart
//                 </div>
//                 {(enableAbandonedCart && onBoardingStatus != '2') ? <div className="initsat-accordion-content">
//                     <p style={{marginBottom:10}}>Abandoned cart</p>
//                     <button className="Polaris-Button Polaris-Button--primary" type="button">
//                                   <span className="Polaris-Button__Content">
//                                     <span className="Polaris-Button__Text"  id="enable_abandoned_cart"><Link to={`/abandoned-cart?shop=${shop}`}>Enable Abandoned cart</Link></span>
//                                   </span>
//                     </button>
//                 </div> : '' }
//             </div>
//         </div>
//     </div>
// </div>

export default OnBoarding;