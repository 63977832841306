import React, {useCallback, useEffect, useState} from "react"
import {
    IndexTable,
    LegacyCard,
    useIndexResourceState,
    Text,
    Badge,
    Layout,
    Page,
    VerticalStack,
    TextField,
    Button,
    Icon,
    HorizontalGrid,
    FormLayout,
    Select,
    Pagination,Divider,Link
} from "@shopify/polaris";
import DateRangePicker from "../components/Datepicker";
import {SearchMinor} from '@shopify/polaris-icons';
import '../assets/style.css'
import axios from "axios";
import {apiEndpoint} from "../helper/config";
import {useSelector} from "react-redux";
const Orders = () => {
    const shop_data = useSelector((state) => {
        return state.clientStore
    });
    const date_range = useSelector((state) => {
        return state.dateRange
    });
    const [isPrevPage,setIsPrevPage] = useState(false)
    const [isNextPage,setIsNextPage] = useState(false)
    const [page,setPage] = useState(0)
    console.log("shop_data================>",date_range,shop_data)
    const [orderStatus, setOrderStatus] = useState('3');
    const [orderData,setOrderData] = useState([])
    const [limit, setLimit] = useState('25');
    const [search,setSearch] = useState('')
    const handleSelectChange = useCallback((value: string) => setOrderStatus(value), [],);
    const handleLimit = useCallback(
        (value: string) => setLimit(value),
        [],
    );
    const handleSearch = useCallback((value) => setSearch(value),[], );
    const options = [
        {label: 'All Status', value: '3'},
        {label: 'Confirmed', value: '1'},
        {label: 'Cancelled', value: '2'},
        {label: 'Pending', value: '0'}
    ];

    const options2 = [
        {label: '25', value: '25'},
        {label: '50', value: '50'},
        {label: '100', value: '100'}
    ];
    const getOrderData = async (query="")=>{
        try {
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            const response = await axios.post(apiEndpoint + `/api/order-log`, {shop_info:shop_data.sdata.shop_info,q:query,status:orderStatus,date:date_range?.date,limit:limit,page_no:page},{
                headers: headers
            }).catch( (err) => {
                console.log("err======================>",err)
            });
            console.log("response.data======================>",response.data)
            setIsNextPage(response.data.isnext)
            setOrderData(response.data.data)

        } catch (error) {
            console.log("error=====catch=============>",error);
        }
    }
    const handleSearchBtn=()=>{
        if(search) {
            console.log("search===================>",search)
            getOrderData(search);
        }
    }
    useEffect(() => {
        if(page == 0){
            setIsPrevPage(false)
        }
        getOrderData("");
    }, [orderStatus,limit,page,date_range]);
    const resourceName = {
        singular: 'order',
        plural: 'orders',
    };

    const {selectedResources, allResourcesSelected, handleSelectionChange} = useIndexResourceState(orderData);

    const rowMarkup = orderData.map(
        (
            {id, order_name,order_id, order_created, customer_fname,customer_lname, price, financial_status, fulfillment_status,verification_status},
            index,
        ) => (
            <IndexTable.Row
                id={id}
                key={id}
                selected={selectedResources.includes(id)}
                position={index}
            >
                <IndexTable.Cell>
                    <Text variant="bodyMd" fontWeight="bold" as="span">
                        <Link url={`https://admin.shopify.com/store/${shop_data?.sdata?.shop_info?.shop_name}/orders/${order_id}`} external>{(verification_status == '2') ? <s>{order_name}</s> : order_name}</Link>
                    </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>{(verification_status == '2') ? <s>{order_created}</s> : order_created}</IndexTable.Cell>
                <IndexTable.Cell>{(verification_status == '2') ? <s>{customer_fname} {customer_lname}</s> : `${customer_fname} ${customer_lname}`}</IndexTable.Cell>
                <IndexTable.Cell>{(verification_status == '2') ? <s>{price}</s> : price}</IndexTable.Cell>
                <IndexTable.Cell>{(verification_status == '1') ? <Badge status="success" progress="complete">Confirmed</Badge> : (verification_status == '0') ? <Badge status="warning" progress='incomplete'> Pending</Badge> : <Badge status="attention" progress="warning">Cancelled </Badge>}</IndexTable.Cell>
                <IndexTable.Cell>{(financial_status === '0') ? <Badge status="warning" progress='incomplete'> Payment pending</Badge> : <Badge status="success" progress="complete">Paid</Badge>}</IndexTable.Cell>
                <IndexTable.Cell>{(fulfillment_status === '0') ? <Badge status="attention" progress="incomplete">Unfulfilled </Badge> : (fulfillment_status === '1') ? <Badge progress="partiallyComplete" status="warning">Partially fullfilled</Badge> : <Badge status="success" progress="complete">Fullfilled</Badge>}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    return (
        <Page
            title="Order logs"
            divider
            fullWidth>
            <Layout>
                <Layout.Section>
                    <VerticalStack gap={{xs: "8", sm: "4"}}>
                        <HorizontalGrid columns={2} gap="4">
                            <div className="initsat-flex-width">
                                <TextField type="text" label="Order Number" value={search} onChange={handleSearch} autoComplete="off"/>
                                <Button primary onClick={handleSearchBtn}><Icon source={SearchMinor} color="base" /></Button>
                                <DateRangePicker/>
                            </div>
                            <div>
                                <FormLayout>
                                    <FormLayout.Group condensed>
                                        <Select
                                            label="Discount value"
                                            options={options}
                                            onChange={handleSelectChange}
                                            value={orderStatus}
                                        />
                                        <Select
                                            label="Show Entries"
                                            options={options2}
                                            onChange={handleLimit}
                                            value={limit}
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </div>
                        </HorizontalGrid>
                        <LegacyCard>
                            <IndexTable
                                resourceName={resourceName}
                                itemCount={orderData.length}
                                selectedItemsCount={
                                    allResourcesSelected ? 'All' : selectedResources.length
                                }
                                onSelectionChange={handleSelectionChange}
                                headings={[
                                    {title: 'Order'},
                                    {title: 'Date'},
                                    {title: 'Customer'},
                                    {title: 'Total', alignment: 'end'},
                                    {title: 'Verification status'},
                                    {title: 'Payment status'},
                                    {title: 'Fulfillment status'},
                                ]}
                            >
                                {rowMarkup}
                            </IndexTable>
                            {(isNextPage || isPrevPage) ?
                                <>
                                <Divider/>
                            <div className="initsat-display-center">
                                <Pagination
                                    hasPrevious={isPrevPage}
                                    previousKeys={[74]}
                                    previousTooltip="Previous J"
                                    onPrevious={() => {
                                        console.log('Previous',page);
                                        setPage(page - 1)
                                    }}
                                    hasNext={isNextPage}
                                    nextKeys={[75]}
                                    nextTooltip="Next K"
                                    onNext={() => {
                                        console.log('Next',page);
                                        setPage(page + 1)
                                        setIsPrevPage(true)
                                    }}
                                />
                            </div> </>: '' }
                        </LegacyCard>
                    </VerticalStack>
                </Layout.Section>

            </Layout>

        </Page>
    );
};

export default Orders;