import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux";
import {
    Page,
    Layout,
    LegacyCard,
    Text,
    Badge,
} from '@shopify/polaris';
import Analytics from "../components/Analytics"
import '@shopify/polaris-viz/build/esm/styles.css';
import axios from "axios";
import {apiEndpoint} from "../helper/config";

const Overview = () => {
    const shop_data = useSelector((state) => {
        return state.clientStore
    });
    const app_status = shop_data?.sdata?.shop_info?.app_status;
    const badge_status = (app_status === '0') ? "critical" : 'success' ;
    const [analyticsData,setAnalyticsData] = useState([])
    const [analyticsGraphData,setAnalyticsGraphData] = useState([]);
    const [trailData,setTrailData] = useState({});

    const getAnalyticsData = async ()=>{
        try {
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            const response = await axios.post(apiEndpoint + `/api/get-analytics`, {shop_info:shop_data.sdata.shop_info},{
                headers: headers
            }).catch( (err) => {
                console.log("err======================>",err)
            });
            console.log("setAnalyticsData======================>",response.data)
            setAnalyticsData(response.data.data)
            setAnalyticsGraphData(response.data.data.analytics_data)
        } catch (error) {
            console.log("error=====catch=============>",error);
        }
    }
    const getTrialDetailsData = async ()=>{
        try {
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            const response = await axios.post(apiEndpoint + `/api/trial_details`, {shop_info:shop_data.sdata.shop_info},{
                headers: headers
            }).catch( (err) => {
                console.log("err======================>",err)
            });
            console.log("setAnalyticsData======================>",response.data)
            setTrailData(response.data.data)
        } catch (error) {
            console.log("error=====catch=============>",error);
        }
    }
    useEffect(() => {
        getAnalyticsData();
        getTrialDetailsData();
    }, []);
    console.log("analyticsData===================overview======================>",analyticsData)
    return (
        <Page title="Overview" fullWidth>
            <Layout>
                <Layout.Section fullWidth>
                    <Analytics graphsData={analyticsGraphData}/>
                </Layout.Section>
                <Layout.Section oneThird>
                    <Layout>
                    <Layout.Section oneThird>
                    <LegacyCard title="Total Orders" >
                        <LegacyCard.Section>

                            <Text variant="heading3xl" as="h2">
                                {analyticsData?.block_data?.total_orders}
                            </Text>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <LegacyCard title="Confirmed Orders" >
                        <LegacyCard.Section>

                            <Text variant="heading3xl" as="h2">
                                {analyticsData?.block_data?.confirmed_orders}
                            </Text>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <LegacyCard title="Canceled Orders" >
                        <LegacyCard.Section>

                            <Text variant="heading3xl" as="h2">
                                {analyticsData?.block_data?.cancelled_orders}
                            </Text>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <LegacyCard title="Pending Orders">
                        <LegacyCard.Section>

                            <Text variant="heading3xl" as="h2">
                                {analyticsData?.block_data?.pending_orders}
                            </Text>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <LegacyCard title="COD To Prepaid Orders Recovered">
                        <LegacyCard.Section>

                            <Text variant="heading3xl" as="h2">
                                {analyticsData?.cod_prepaid?.cod_prepaid_order}
                            </Text>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <LegacyCard title="COD To Prepaid Orders Revenue">
                        <LegacyCard.Section>

                            <Text variant="heading3xl" as="h2">
                                {analyticsData?.cod_prepaid?.cod_prepaid_order_price}
                            </Text>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>

                        </Layout>
                </Layout.Section>

            <Layout.Section oneThird>
                <Layout>
                <Layout.Section oneHalf>
                    <LegacyCard title="Account">
                        <LegacyCard.Section>
                            <div className="initsat-flex-align">
                                <Text variant="headingMd" as="h6">
                                    App Status
                                </Text>
                                <Badge status={badge_status}>{(app_status === '0') ? 'Disable' : 'Enabled'}</Badge>
                            </div>
                            <div className="initsat-flex-align">
                                <Text variant="headingMd" as="h6">
                                    App Mode
                                </Text>
                                <Badge status="success">Whatsapp Mode</Badge>
                            </div>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>

                <Layout.Section oneHalf>
                    <LegacyCard title="Payment">
                        <LegacyCard.Section>

                            <div className="initsat-flex-align">
                                <Text variant="headingMd" as="h6">
                                    Payment Mode
                                </Text>
                                <Badge status="success">{trailData?.payment_mode}</Badge>
                            </div>
                            <div className="initsat-flex-align">
                                <Text variant="headingMd" as="h6">
                                    Bill
                                </Text>
                                <Text variant="headingLg" as="h5">
                                    {`${trailData?.pricing}USD`}
                                </Text>

                            </div>
                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>

                    </Layout>
            </Layout.Section>

            </Layout>
            <Layout>
                <Layout.Section oneThird>
                    <LegacyCard title="Abandoned Cart Orders Recovered">
                        <LegacyCard.Section>

                            <Text variant="heading3xl" as="h2">
                                {analyticsData?.abondoned_cart?.abandoned_cart_order}
                            </Text>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <LegacyCard title="Abandoned Cart Orders Revenue">
                        <LegacyCard.Section>

                            <Text variant="heading3xl" as="h2">
                                {analyticsData?.abondoned_cart?.abandoned_cart_order_price}
                            </Text>

                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
            </Layout>
        </Page>
    );
};
export default Overview;