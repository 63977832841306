import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import store from "./store/index";
import reportWebVitals from './reportWebVitals';
import {AppProvider} from '@shopify/polaris';
import {Provider} from "react-redux";
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppProvider i18n={enTranslations}>
        <Provider store={store}>
            <App/>
        </Provider>
    </AppProvider>
);
reportWebVitals();
