import React from "react"
import {LegacyCard} from "@shopify/polaris";

import { BarChart } from "@shopify/polaris-viz";
import '@shopify/polaris-viz/build/esm/styles.css';

export default function Analytics({graphsData}) {
    console.log("graphData==========0==========>",graphsData)
    return (
        <LegacyCard sectioned title="View activity in the last 10 days">
            <LegacyCard.Section>
                <BarChart
                    theme="Light"
                    xAxisOptions={{
                        labelFormatter: (x) => {
                            return `${x}`
                        }
                    }}
                    yAxisOptions={{
                        labelFormatter: (y) => {
                            return `${y}`
                        }
                    }}
                    data={graphsData}
                />
            </LegacyCard.Section>
        </LegacyCard>
    );
}