import React, {useCallback, useEffect, useState} from "react"
import {useSelector, useDispatch} from "react-redux";
import {
    AlphaCard,
    Box, Button, Checkbox, Divider,Frame,
    FormLayout,
    HorizontalGrid, Layout,
    Page,
    Select,
    Text,
    TextField, useBreakpoints,
    VerticalStack,
    Spinner,Toast
} from "@shopify/polaris";

import {followUpOptions,
        StatusOp,
        voiceOptions,
        followUpMsgHoursOptions,
        followUpCallOptions,
        voiceDelayOptions,
        quitHoursList, voiceCallMessage} from "../helper/static_data";
import axios from "axios";
import {apiEndpoint} from "../helper/config";
import {updateData} from "../store/slices/storeSlices";
import Verification from "../components/configure/Verification"

const Configure = () => {
    const dispatch = useDispatch();
    const shop_data = useSelector((state) => {
        return state.clientStore
    });

    console.log("shop_data=============Configure==============>",shop_data)
    const {smUp} = useBreakpoints();
    const [isNeedFollowUp, setIsNeedFollowUp] = useState('0');
    const [generalData,setGeneralData] = useState({})
    const [followUpMsgHours, setFollowUpMsgHours] = useState('2');
    const [followUpBtn, setFollowUpBtn] = useState(true);
    const [isCancelOrderChecked, setIsCancelOrderChecked] = useState(false);
    const [cancelBtn, setCancelBtn] = useState(true);
    const [cancelLoading,setCancelLoading] = useState(false);
    const [isPrepaidOrderVerifyChecked, setPrepaidOrderVerifyChecked] = useState(false);
    const [prepaidOrderBtn, setPrepaidOrderBtn] = useState(true);
    const [prepaidLoading,setPrepaidLoading] = useState(false);
    const [loading,setLoading] = useState(false);

    const [isDisabled,setDisabled] = useState(false);
    const [codVerifyTemplate,setCodVerifyTemplate] = useState("");

    const [orderTagObj, setOrderTagObj] = useState({orderConfirmedTag: '',orderCancelledTag:'',orderPrepaidTag:'',noResponseTag:''});
    const [tagBtn,setTagBtn] = useState(true);
    const [tagLoading, setTagLoading] = useState(false);

    const [error, setError] = useState(false);
    const [active, setActive] = useState(false);
    const [toastMsg,setToastMsg] = useState("Message sent");

    const [voiceLanguage,setVoiceLanguage] = useState('0');
    const [voiceDisabled,setVoiceDisabled] = useState(true);

    const [voiceDelayStatus,setVoiceDelayStatus] = useState('0');
    const [voiceDelayTime,setVoiceDelayTime] = useState('0');
    const [voiceFollowStatus,setVoiceFollowStatus] = useState('0')
    const [voiceFollow2Status,setVoiceFollow2Status] = useState('0')
    const [voiceFollowUpTime,setVoiceFollowUpTime] = useState('1')
    const [quitHoursValue,setQuitHours] = useState(quitHoursList);

    const tag_json = {
        'orderConfirmedTag':'order_confirmed_tag',
        'orderCancelledTag':'order_cancelled_tag',
        'orderPrepaidTag': 'order_prepaid_tag',
        'noResponseTag': 'no_response_tag'
    }

    const handleDelayFromValueChange = (value,id) => {
        setQuitHours({...quitHoursValue, [id]: value});
    }
    const handleVoiceFollowUpChange = useCallback((value: string) => {
        setVoiceDisabled(false)
        setVoiceFollowUpTime(value)
    },[]);
    const handleVoiceFollow2StatusOpChange = useCallback((value: string) => {
        setVoiceDisabled(false)
        setVoiceFollow2Status(value)
    },[]);
    const handleVoiceDelayStatusOpChange = useCallback((value: string) => {
        setVoiceDisabled(false)
        setVoiceDelayStatus(value)
    },[]);
    const handleVoiceFollowStatusOpChange =  useCallback((value: string) => {
        setVoiceDisabled(false)
        setVoiceFollowStatus(value)
    },[]);

    const handleVoiceDelayChange = useCallback((value: string) => {
        setVoiceDelayTime(value)
    },[]);

    const handleVoiceSelectChange = useCallback((value: string) => {
        setVoiceDisabled(false)
        setVoiceLanguage(value)
    },[]);

    const handleFollowUpSelectChange = useCallback(
        (value: string) => {
            // let is_followup_btn = (generalData?.is_cancel_order_shopify == '0') ? false : true;
            let button = (value != generalData?.is_follow_up_msg_enable);
            setFollowUpBtn(!button)
            setIsNeedFollowUp(value)
    },[generalData?.is_follow_up_msg_enable]);
    const handleSelectChange2 = useCallback(
        (value: string) => {
            let button = (value != generalData?.follow_up_msg_hours);
            setFollowUpBtn(!button)
            setFollowUpMsgHours(value)
        },[generalData?.follow_up_msg_hours]);

    const handleChange = useCallback(
        (newChecked: boolean) => {
            let is_cancel = (generalData?.is_cancel_order_shopify == '0') ? false : true;
            let button = (newChecked != is_cancel);
            setCancelBtn(!button)
            setIsCancelOrderChecked(newChecked)
        }, [generalData?.is_cancel_order_shopify]);
    const handleIsPrepaidOrderChange = useCallback(
        (newChecked: boolean) => {
            let is_prepaid = (generalData?.is_prepaid_order_verify == '0') ? false : true;
            let button = (newChecked != is_prepaid);
            setPrepaidOrderBtn(!button)
            setPrepaidOrderVerifyChecked(newChecked)
        },[generalData?.is_prepaid_order_verify]);
    const handleOrderTagChange = useCallback((value,id)=>{
        let tag_text = generalData[tag_json[id]];
        console.log("tag_text====================>",value,id,tag_text)
        let button = (value != tag_text);
        setTagBtn(!button)
        setOrderTagObj(prevState => ({ ...prevState, [id]: value }));
    },[generalData])

    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const toastMarkup = active ? (<Toast content={toastMsg} onDismiss={toggleActive} error={error}/>) : null;

    const handleEnableBtn = ()=>{
        setLoading(true)
        setDisabled(true)
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/enable-app',{shop_info: shop_data.sdata.shop_info},{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                let status = (shop_data?.sdata?.shop_info?.app_status == '0') ? '1' : '0'
                dispatch(updateData(status));
            }
        }).catch((error)=>{
            setLoading(false)
            setDisabled(false)
        }).finally(()=>{
            setLoading(false)
            setDisabled(false)
        });
    }

    const handleFollowUpBtn = () =>{
        setLoading(true)
        let data = {is_follow_up_msg_enable:isNeedFollowUp,follow_up_msg_hours:followUpMsgHours};
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/save-followup-settings',{shop_info: shop_data.sdata.shop_info,data},{
            headers: headers
        }).then((response) => {
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved")
                setGeneralData({...generalData, is_follow_up_msg_enable:isNeedFollowUp, follow_up_msg_hours:followUpMsgHours});
                setLoading(false)
                setFollowUpBtn(true)
            } else{
                setToastMsg("Something went wrong")
                setError(true)
                setFollowUpBtn(true)
            }

        }).catch((error)=>{
            setToastMsg("Something went wrong")
            setLoading(false)
            setError(true)
        }).finally(()=>{
            setLoading(false)
            toggleActive()
        });
    }

    const handleCancelOrderBtn = () =>{
        console.log("handleEnableBtn================click====================>")
        setCancelLoading(true)
        let data = {is_cancel_order_shopify:isCancelOrderChecked};
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/save-cancel-order-settings',{shop_info: shop_data.sdata.shop_info,data},{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                let cancel_btn_data=(isCancelOrderChecked) ? '1' : '0';
                setToastMsg("Successfully Saved");
                setGeneralData({...generalData,is_cancel_order_shopify:cancel_btn_data});
                setCancelBtn(true)
            }else{
                setToastMsg("Something went wrong")
                setError(true)
                setCancelBtn(true)
            }

        }).catch((error)=>{
            setToastMsg("Something went wrong")
            setCancelLoading(false)
            setError(true)
        }).finally(()=>{
            setCancelLoading(false)
            toggleActive()
        });
    }



    const handlePrepaidOrderBtn = ()=>{
        console.log("handleEnableBtn================click====================>")
        setPrepaidLoading(true)
        let data = {is_prepaid_order_verify:isPrepaidOrderVerifyChecked};
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/save-prepaid-order-settings',{shop_info: shop_data.sdata.shop_info,data},{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved")
                setPrepaidOrderBtn(true)
                setGeneralData({...generalData,is_prepaid_order_verify:isPrepaidOrderVerifyChecked});
            } else {
                setToastMsg("Something went wrong")
                setError(true)
                setPrepaidOrderBtn(true)
            }
        }).catch((error)=>{
            setToastMsg("Something went wrong")
            setPrepaidLoading(false)
            setError(true)
        }).finally(()=>{
            setPrepaidLoading(false)
            toggleActive()
        });
    }

    const handleSaveOrderTagBtn = ()=>{
        console.log("handleEnableBtn================click====================>")
        setTagLoading(true)
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/save-order-tags-settings',{shop_info: shop_data.sdata.shop_info,data:orderTagObj},{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved");
                setGeneralData({...generalData, order_confirmed_tag : orderTagObj.orderConfirmedTag, order_cancelled_tag: orderTagObj.orderCancelledTag, order_prepaid_tag: orderTagObj.orderPrepaidTag,no_response_tag: orderTagObj.noResponseTag});
                setTagBtn(true)
            } else{
                setToastMsg("Something went wrong")
                setError(true)
                setTagBtn(true)
            }

        }).catch((error)=>{
            setToastMsg("Something went wrong")
            setTagLoading(false)
            setError(true)
        }).finally(()=>{
            setTagLoading(false)
            toggleActive()
        });
    }
    const handleVoiceSave = ()=>{
        console.log("handleEnableBtn================click====================>")
        setTagLoading(true)
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/save-voice-language',{shop_info: shop_data.sdata.shop_info,voice:voiceLanguage},{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved")
                setTagBtn(true)
            } else{
                setToastMsg("Something went wrong")
                setError(true)
                setTagBtn(true)
            }

        }).catch((error)=>{
            setToastMsg("Something went wrong")
            setTagLoading(false)
            setError(true)
        }).finally(()=>{
            setTagLoading(false)
            setVoiceDisabled(true)
            toggleActive()
        });
    }

    const handleVoiceDelayCallDetailSave= ()=>{

        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        let data_send = {
            shop_info: shop_data.sdata.shop_info,
            voice_delay_status: voiceDelayStatus,
            voice_delay: voiceDelayTime,
            quiet_hours: `${quitHoursValue.from}_@_${quitHoursValue.to}`
        }
        axios.post(apiEndpoint+ '/api/save-voice-delay-settings',data_send,{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved")
                setTagBtn(true)
            } else{
                setToastMsg("Something went wrong")
                setError(true)
                setTagBtn(true)
            }

        }).catch((error)=>{
            setToastMsg("Something went wrong")
            setError(true)
        }).finally(()=>{

            setVoiceDisabled(true)
            toggleActive()
        });
    }

    const handleVoiceFollowUpCallDetailSave=()=>{

        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        let data_send = {
            shop_info: shop_data.sdata.shop_info,
            followup1_delay_status: voiceFollowStatus,
            voice_follow_up_time: voiceFollowUpTime,
            followup2_delay_status: voiceFollow2Status
        }
        axios.post(apiEndpoint+ '/api/save-voice-followup-settings',data_send,{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved")
                setTagBtn(true)
            } else{
                setToastMsg("Something went wrong")
                setError(true)
                setTagBtn(true)
            }

        }).catch((error)=>{
            setToastMsg("Something went wrong")
            setError(true)
        }).finally(()=>{

            setVoiceDisabled(true)
            toggleActive()
        });
    }

    const getGeneralSettings = ()=>{
        console.log("handleEnableBtn================click====================>")
        setLoading(true)
        setDisabled(true)
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/get-general-settings',{shop_info: shop_data.sdata.shop_info},{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                let res_data = response.data.data;
                setGeneralData(res_data);
                let cancel_order = (res_data?.is_cancel_order_shopify == '1') ? true : false
                let prepaid_order = (res_data?.is_prepaid_order_verify == '1') ? true : false
                setIsNeedFollowUp(res_data?.is_follow_up_msg_enable)
                setFollowUpMsgHours(res_data?.follow_up_msg_hours)
                setIsCancelOrderChecked(cancel_order)
                setPrepaidOrderVerifyChecked(prepaid_order)
                setOrderTagObj({...orderTagObj, ['orderConfirmedTag'] : res_data.order_confirmed_tag,['orderCancelledTag']: res_data.order_cancelled_tag,['orderPrepaidTag']: res_data.order_prepaid_tag,['noResponseTag']: res_data.no_response_tag});
                setCodVerifyTemplate(res_data?.cod_verify_template)
                if(res_data?.app_mode == '1' || res_data?.app_mode == '2'){
                    setVoiceLanguage(res_data?.voice_data?.ivr_voice_type);
                    setVoiceDelayStatus(res_data?.voice_data?.is_enable_delay);
                    setVoiceDelayTime(res_data?.voice_data?.delay_time);
                    setVoiceFollowStatus(res_data?.voice_data?.followup1_status);
                    setVoiceFollow2Status(res_data?.voice_data?.followup2_status);
                    setVoiceFollowUpTime(res_data?.voice_data?.followup_waiting_time);
                    let quiet_hours = res_data?.voice_data?.quiet_hours;
                    let from = quiet_hours.split('_@_')
                    setQuitHours({...quitHoursValue, ['from']: from[0],['to']:from[1]});
                }
            }

        }).catch((error)=>{
            setLoading(false)
            setDisabled(false)
        }).finally(()=>{
            setLoading(false)
            setDisabled(false)
        });
    }
    useEffect(() => {
        getGeneralSettings();
    }, []);
    return (<>
        <Frame>
        <Page title="Settings" divider fullWidth>
            <Layout>
                <Layout.Section oneThird>
                    <VerticalStack gap={{xs: "8", sm: "4"}}>
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Application status
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Enable or disable the app.
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <div className="initsat-flex-align-conf">

                                    <Text as="p" variant="bodyMd">
                                        Your app is <Text as="span" fontWeight="bold">{(shop_data?.sdata?.shop_info?.app_status === '0') ? 'Disabled' : 'Enabled'}</Text> now
                                    </Text>
                                    {(shop_data?.sdata?.shop_info?.app_status === '0') ?  <Button variant="primary"  onClick={handleEnableBtn}>{(loading) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled}/> : 'Enable'}</Button> :
                                        <Button destructive onClick={handleEnableBtn}>{(loading) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled}/> : 'Disable'} </Button> }
                                </div>
                            </AlphaCard>
                        </HorizontalGrid>

                        {smUp ? <Divider/> : null}

                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Verification Mode
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        On place new COD order action will be done based on selected mode and as per customer input app will add tags in the order.
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <Verification getGeneralSettings={getGeneralSettings} setToastMsg={setToastMsg} setError={setError} toggleActive={toggleActive}/>

                        </HorizontalGrid>
                        {(shop_data?.sdata?.shop_info?.app_mode == 1 || shop_data?.sdata?.shop_info?.app_mode == 2) ?
                            <>
                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Call Template
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        You can set call text as per your requirement which will send to customers.
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Available Short-codes:-
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <VerticalStack gap="4">
                                    <Select label="Select a IVR voice" options={voiceOptions} onChange={handleVoiceSelectChange} value={voiceLanguage}/>
                                    <TextField label="Text to say:" multiline={4} autoComplete="off" disabled value={voiceCallMessage[voiceLanguage].msg} />
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <div>
                                                <TextField
                                                    type="text"
                                                    autoComplete="on" id="say1" disabled value={voiceCallMessage[voiceLanguage].say_press_1} label="Say press 1:"/>
                                                <div>An order confirmation message will be triggered if the user press 1.</div>
                                            </div>
                                            <div>
                                                <TextField
                                                    type="text"
                                                    autoComplete="on" id="say2" disabled value={voiceCallMessage[voiceLanguage].say_press_2}  label="Say press 2:"/>
                                                <div>An order cancellation message will be triggered if the user press 2.</div>
                                            </div>
                                        </FormLayout.Group>
                                    </FormLayout>
                                    <Button primary  onClick={handleVoiceSave} size="medium" loading={loading} disabled={voiceDisabled}> Save </Button>
                                </VerticalStack>
                            </AlphaCard>

                        </HorizontalGrid>
                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Schedule delay call
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                            <VerticalStack gap="4">
                                <Select label="Status" options={StatusOp} onChange={handleVoiceDelayStatusOpChange} value={voiceDelayStatus}/>
                                {(voiceDelayStatus == '1') ?
                                 <>
                                    <Select label="You can also select the Main calls time intervels in drop down list" options={voiceDelayOptions} onChange={handleVoiceDelayChange} value={voiceDelayTime}/>
                                     {smUp ? <Divider/> : null}
                                     <div>
                                     <Text as="b" variant="bodyLg">Quiet hours</Text>
                                     <Text as="p" variant="bodyMd">Works based on the time zone you have selected. Do not send any call between the specified time span.</Text>
                                     </div>
                                      <FormLayout>
                                        <FormLayout.Group>
                                            <div>
                                            <TextField
                                                type="time"
                                                name="appt"
                                                min="00:00" max="23:59"
                                                autoComplete="on" id="from" value={quitHoursValue.from} onChange={(e,id) => {setVoiceDisabled(false);handleDelayFromValueChange(e,id)}} label="FROM"/>
                                            <div>Recommended 23:00 (Night)</div>
                                            </div>
                                            <div>
                                                <TextField
                                                type="time"
                                                name="appt"
                                                min="00:00" max="23:59"
                                                autoComplete="on" id="to" value={quitHoursValue.to} onChange={(e,id) => {setVoiceDisabled(false);handleDelayFromValueChange(e,id)}} label="TO"/>
                                            <div>Recommended 08:00 (Morning)</div>
                                            </div>
                                            </FormLayout.Group>
                                    </FormLayout>
                                 </> : ''}
                                <Button primary  onClick={handleVoiceDelayCallDetailSave} size="medium" loading={loading} disabled={voiceDisabled}> Save </Button>
                            </VerticalStack>
                            </AlphaCard>

                        </HorizontalGrid>

                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Follow-up Settings
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Trigger follow-up calls if your customer doesn't answer the first call.
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <VerticalStack gap="4">
                                    <Select label="Followup 1" options={StatusOp} onChange={handleVoiceFollowStatusOpChange} value={voiceFollowStatus}/>
                                    {(voiceFollowStatus == '1') ?
                                        <>
                                            <Select label="Delay between followup" options={followUpCallOptions} onChange={handleVoiceFollowUpChange} value={voiceFollowUpTime}/>
                                            <Select label="Followup 2" options={StatusOp} onChange={handleVoiceFollow2StatusOpChange} value={voiceFollow2Status}/>

                                        </> : ''}
                                    <Button primary  onClick={handleVoiceFollowUpCallDetailSave} size="medium" loading={loading} disabled={voiceDisabled}> Save </Button>
                                </VerticalStack>
                            </AlphaCard>

                        </HorizontalGrid>
                            </>: ''}
                        {(shop_data?.sdata?.shop_info?.app_mode == 0 || shop_data?.sdata?.shop_info?.app_mode == 2) ?
                         <>
                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Cash on Delivery (COD) Order Confirmation Template
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Verify and confirm COD orders using WhatsApp. Message is sent immediately
                                        after a COD order is placed.
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Automated WhatsApp message templates cannot be modified or edited, because
                                        message templates need to be pre-approved by WhatsApp before they can be
                                        sent using the API.
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <VerticalStack gap="4">
                                    <TextField label="COD Order Confirmation" multiline={4} autoComplete="off" disabled value={codVerifyTemplate} />
                                </VerticalStack>
                            </AlphaCard>

                        </HorizontalGrid>
                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Follow-up Settings
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Send an additional follow up WhatsApp message to customers who haven’t confirmed
                                        their order.
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Automated WhatsApp message templates cannot be modified or edited, because
                                        message templates need to be pre-approved by WhatsApp before they can be
                                        sent using the API.
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Based on the selected time app will auto-send the WhatsApp message to the
                                        customer and ask for confirmation.
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <VerticalStack gap="4">
                                    <Select
                                        label="Trigger follow-up message for unverified orders"
                                        options={followUpOptions}
                                        onChange={handleFollowUpSelectChange}
                                        value={isNeedFollowUp}
                                    />
                                    <Select
                                        label="Re-attempt after"
                                        options={followUpMsgHoursOptions}
                                        onChange={handleSelectChange2}
                                        value={followUpMsgHours}
                                    />
                                    <Button primary onClick={handleFollowUpBtn} className={"btn-me-work"} disabled={followUpBtn} loading={loading}>Save</Button>
                                </VerticalStack>
                            </AlphaCard>
                        </HorizontalGrid>
                         </>: ''}
                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Cancel Order
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Want to automatically cancel order and restock items?
                                    </Text>

                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <VerticalStack gap="4">
                                    <div className="initsat-flex-align-check">
                                        <Checkbox
                                            label=""
                                            checked={isCancelOrderChecked}
                                            onChange={handleChange}
                                        />
                                        <Text as="p" variant="bodyMd">
                                            If an order is cancelled, automatically cancel the order in Shopify.
                                        </Text>

                                    </div>
                                    <Button primary onClick={handleCancelOrderBtn} disabled={cancelBtn} loading={cancelLoading}>Save</Button>
                                </VerticalStack>
                            </AlphaCard>
                        </HorizontalGrid>
                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Prepaid Orders
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Would you like to validate pre-paid orders?
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <VerticalStack gap="4">
                                    <div className="initsat-flex-align-check">
                                        <Checkbox
                                            label=""
                                            checked={isPrepaidOrderVerifyChecked}
                                            onChange={handleIsPrepaidOrderChange}
                                        />
                                        <Text as="p" variant="bodyMd">
                                            Enable verification for non-COD/Prepaid orders as well.
                                        </Text>
                                    </div>
                                    <Button primary onClick={handlePrepaidOrderBtn} disabled={prepaidOrderBtn} loading={prepaidLoading}>Save</Button>
                                </VerticalStack>
                            </AlphaCard>
                        </HorizontalGrid>
                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}>
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Tags for COD Orders
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Automatically add tags to your orders based on COD order status.
                                    </Text>
                                </VerticalStack>

                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <FormLayout>
                                    <FormLayout.Group>
                                        <TextField
                                            type="text"
                                            label="Order Confirmed Tag"
                                            onChange={(e,id) => handleOrderTagChange(e,id)}

                                            id="orderConfirmedTag"
                                            value={orderTagObj.orderConfirmedTag}
                                            autoComplete="off"
                                        />
                                        <TextField
                                            type="text"
                                            label="Order Cancelled Tag"
                                            id="orderCancelledTag"
                                            value={orderTagObj.orderCancelledTag}
                                            onChange={(e,id) => handleOrderTagChange(e,id)}
                                            autoComplete="off"
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <TextField
                                            type="text"
                                            label="Order Prepaid Tag"
                                            id="orderPrepaidTag"
                                            value={orderTagObj.orderPrepaidTag}
                                            onChange={(e,id) => handleOrderTagChange(e,id)}
                                            autoComplete="off"
                                        />
                                        <TextField
                                            type="text"
                                            label="No Response Tag"
                                            id="noResponseTag"
                                            value={orderTagObj.noResponseTag}
                                            onChange={(e,id) => handleOrderTagChange(e,id)}
                                            autoComplete="off"
                                        />
                                    </FormLayout.Group>
                                    <Button primary onClick={handleSaveOrderTagBtn} disabled={tagBtn} loading={tagLoading}>Save</Button>
                                </FormLayout>
                            </AlphaCard>
                        </HorizontalGrid>
                    </VerticalStack>
                </Layout.Section>
            </Layout>
            {toastMarkup}
        </Page>
        </Frame>
    </>)
}

export default Configure