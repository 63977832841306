const followUpOptions = [
    {label: 'Disable', value: '0'},
    {label: 'Enable', value: '1'}
];

const StatusOp = [
    {label: 'Disable', value: '0'},
    {label: 'Enable', value: '1'}
]
const voiceOptions = [
    {label: 'English Voice', value: '0'},
    {label: 'Hindi Voice', value: '1'},
]

const followUpMsgHoursOptions = [
    {label: '2 hours', value: '2'},
    {label: '4 hours', value: '4'},
    {label: '6 hours', value: '6'}
]

const followUpCallOptions = [
    {label: '20 minutes', value: '0'},
    {label: '40 minutes (recommended)', value: '1'},
    {label: '60 minutes', value: '2'}
]

const voiceDelayOptions =[
    {label: '1 minutes', value: '0'},
    {label: '5 minutes', value: '1'},
    {label: '15 minutes', value: '2'},
    {label: '30 minutes', value: '3'},
    {label: '1 hours', value: '4'},
    {label: '2 hours', value: '5'},
    {label: '3 hours', value: '6'}
]
const quitHoursList = {from:'23:00:00',to:'08:00:00'}

const voiceCallMessage={
    "0":{"msg":"Welcome to to Cash on Delivery order verification. Please press 1 for order confirmation, Press 2 for order cancellation , Press 3 for listening again.","say_press_1":"Your order has been confirmed.","say_press_2":"Your order has been canceled."},
    "1":{"msg":"हमारी वेबसाइट प्रति ऑर्डर करने के लिए धन्यवाद। ऑर्डर कन्फर्म करने के लिए 1 दबाये, ऑर्डर कैंसिल करने के लिए 2 दबाये, मैसेज को दोबारा सुनने के लिए 3 दबाये।","say_press_1":"आपका ऑर्डर कन्फर्म हो गया है..","say_press_2":"आपका ऑर्डर कैंसिल कर दिया गया है.."}
}

const discountOptions = [
    {label: 'Percentage%', value: '0'},
    {label: 'Fixed Amount', value: '1'}
];
const paymentOptions=[
    {label: 'Cashfree', value: '0'},
    {label: 'Razorpay', value: '1'},
    // {label: 'PayU', value: '2'}
];
const codPrepaidTriggerOptions = [
    {label: 'Immediately', value: '0'},
    {label: '12 hour later', value: '1'},
    {label: '24 hour later', value: '2'},
    {label: 'Trigger automatically after order confirmation via Call/WhatsApp', value: '3'}
];
const codPrepaidLinkExpireOptions = [
    {label: '2', value: '0'},
    {label: '4', value: '1'},
    {label: '8', value: '2'},
    {label: '16', value: '3'},
    {label: '24', value: '4'}
]
export {
    followUpOptions,
    StatusOp,
    voiceOptions,
    followUpMsgHoursOptions,
    followUpCallOptions,
    voiceDelayOptions,
    quitHoursList,
    voiceCallMessage,
    discountOptions,
    paymentOptions,
    codPrepaidTriggerOptions,
    codPrepaidLinkExpireOptions
};