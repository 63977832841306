import React, {useCallback, useEffect, useState} from "react";

import {
    IndexTable,
    LegacyCard,
    useIndexResourceState,
    Badge,
    Layout,
    Page,
    VerticalStack,
    HorizontalGrid,
    FormLayout,
    Select,
    Text,
    Link,
    Pagination,Divider
} from "@shopify/polaris";
import DateRangePicker from "../components/Datepicker";
import {SearchMinor} from '@shopify/polaris-icons';
import '../assets/style.css'
import axios from "axios";
import {apiEndpoint} from "../helper/config";
import {useSelector} from "react-redux";
const AbandonedCartLogs = () => {
    const shop_data = useSelector((state) => {
        return state.clientStore
    });
    const date_range = useSelector((state) => {
        return state.dateRange
    });
    const [isPrevPage,setIsPrevPage] = useState(false)
    const [isNextPage,setIsNextPage] = useState(false)
    const [page,setPage] = useState(0)
    console.log("shop_data================>",date_range,shop_data)
    const [cartStatus, setCartStatus] = useState('2');
    const [orderData,setOrderData] = useState([])
    const [limit, setLimit] = useState('25');
    //const [search,setSearch] = useState('')
    const handleSelectChange = useCallback((value: string) => setCartStatus(value), [],);
    const handleLimit = useCallback(
        (value: string) => setLimit(value),
        [],
    );
    //const handleSearch = useCallback((value) => setSearch(value),[], );
    const cartOptions = [
        {label: 'All', value: '2'},
        {label: 'Recovered', value: '1'},
        {label: 'Not Recovered', value: '0'}
    ];

    const options2 = [
        {label: '25', value: '25'},
        {label: '50', value: '50'},
        {label: '100', value: '100'}
    ];
    const getAbandonedOrderData = async () => {
        try {
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data?.sdata?.authentication
            }
            console.log("cartStatus======================>",cartStatus)
            let data = {shop_info:shop_data.sdata.shop_info,status:cartStatus,date:date_range?.date,limit:limit,page_no:page}
            const response = await axios.post(apiEndpoint + `/api/get-abandoned-cart-orders`, data,{
                headers: headers
            }).catch( (err) => {
                console.log("err======================>",err)
            });

            setIsNextPage(response.data.isnext)
            //setPage(response.data.page)
            setOrderData(response.data.data)

        } catch (error) {
            console.log("error=====catch=============>",error);
        }
    }
    // const handleSearchBtn=()=>{
    //     if(search) {
    //         console.log("search===================>",search)
    //         getAbandonedOrderData(search);
    //     }
    // }
    useEffect(() => {
        if(page == 0){
            setIsPrevPage(false)
        }
        getAbandonedOrderData("");
    }, [cartStatus,limit,page,date_range]);
    const resourceName = {
        singular: 'order',
        plural: 'orders',
    };

    const {selectedResources, allResourcesSelected, handleSelectionChange} = useIndexResourceState(orderData);
    console.log("shop_data================>",shop_data)
    const rowMarkup = orderData.map(
        (
            {id,adc_id,adc_name, customer_email,customer_phone,customer_fname,customer_lname,total_price,store_tz_created,cart_status},
            index,
        ) => (
            <IndexTable.Row
                id={id}
                key={id}
                selected={selectedResources.includes(id)}
                position={index}

            >
                <IndexTable.Cell><Link url={`https://admin.shopify.com/store/${shop_data?.sdata?.shop_info?.shop_name}/checkouts/${adc_id}`} external><Text variant="bodyMd" fontWeight="bold" as="span">{(adc_name)?`${adc_name}`:''}</Text></Link></IndexTable.Cell>
                <IndexTable.Cell>{`${customer_fname} ${customer_lname}`}</IndexTable.Cell>
                <IndexTable.Cell>{(customer_email) ? customer_email : ''}</IndexTable.Cell>
                <IndexTable.Cell>{(customer_phone) ? customer_phone : ''}</IndexTable.Cell>
                <IndexTable.Cell>{store_tz_created}</IndexTable.Cell>
                <IndexTable.Cell>{total_price}</IndexTable.Cell>
                <IndexTable.Cell>{(cart_status === '0') ? <Badge status="attention" progress="incomplete">Not Recovered </Badge> : <Badge status="success" progress="complete">Recovered</Badge>}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    return (
        <Page
            title="Abandoned cart"
            divider
            fullWidth>
            <Layout>
                <Layout.Section>
                    <VerticalStack gap={{xs: "8", sm: "4"}}>
                        <HorizontalGrid columns={2} gap="4">
                            <div>
                                <FormLayout>
                                    <FormLayout.Group condensed>
                                        <Select
                                            label="Cart status"
                                            options={cartOptions}
                                            onChange={handleSelectChange}
                                            value={setCartStatus}
                                        />
                                        <Select
                                            label="Show Entries"
                                            options={options2}
                                            onChange={handleLimit}
                                            value={limit}
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </div>
                        </HorizontalGrid>
                        <LegacyCard>
                            <IndexTable
                                resourceName={resourceName}
                                itemCount={orderData.length}
                                selectedItemsCount={
                                    allResourcesSelected ? 'All' : selectedResources.length
                                }
                                onSelectionChange={handleSelectionChange}
                                headings={[
                                    {title: 'Checkout'},
                                    {title: 'Customer name'},
                                    {title: 'Email'},
                                    {title: 'Phone'},
                                    {title: 'Date'},
                                    {title: 'Total', alignment: 'end'},
                                    {title: 'Cart status'}
                                ]}
                            >
                                {rowMarkup}
                            </IndexTable>
                            {(isNextPage || isPrevPage) ?
                                <>
                                    <Divider/>
                                    <div className="initsat-display-center">
                                        <Pagination
                                            hasPrevious={isPrevPage}
                                            previousKeys={[74]}
                                            previousTooltip="Previous J"
                                            onPrevious={() => {
                                                console.log('Previous',page);
                                                setPage(page - 1)
                                            }}
                                            hasNext={isNextPage}
                                            nextKeys={[75]}
                                            nextTooltip="Next K"
                                            onNext={() => {
                                                console.log('Next',page);
                                                setPage(page + 1)
                                                setIsPrevPage(true)
                                            }}
                                        />
                                    </div> </>: '' }
                        </LegacyCard>
                    </VerticalStack>
                </Layout.Section>

            </Layout>

        </Page>
    );
};

export default AbandonedCartLogs;