import React, {useState, useCallback, useEffect} from "react"
import {useSelector} from "react-redux";
import {
    AlphaCard,
    Box,
    HorizontalGrid,
    Page,
    Text,
    TextField,
    VerticalStack,
    Divider,
    Select, Frame,
    useBreakpoints, FormLayout, Button, Toast, Spinner
} from "@shopify/polaris";
import axios from "axios";
import {apiEndpoint} from "../helper/config";
import {discountOptions,paymentOptions,codPrepaidTriggerOptions,codPrepaidLinkExpireOptions} from "../helper/static_data";

const Codprepaid = () => {
    const paymentGatewayShow = false
    const shop_data = useSelector((state) => {return state.clientStore});

    let default_button_state = {payment_selected_state:true,expire_time_state:true,minimum_order_value_state:true,prepaid_order_tag_status:true,trigger_time_state:true}
    let loading_button_state = {prepaid_enable_state:false,payment_selected_state:false,expire_time_state:false,minimum_order_value_state:false,prepaid_order_tag_status:false,trigger_time_state:false}
    const [codPrepaidData, setCodPrepaidData] = useState({});
    const [selected, setSelected] = useState('0');
    const [payment_selected, setPaymentSelected] = useState('0');
    const [discountValue,setDiscountValue] = useState('');
    const [checked, setChecked] = useState(false);
    const [loading,setLoading] = useState(loading_button_state);

    const [isDisabled,setIsDisabled] = useState(default_button_state);
    const [expireTime, setExpireTime] = useState('0');
    const [minimumOrderValue, setMinimumOrderValue] = useState('');
    const [prepaidOrderTag, setPrepaidOrderTag] = useState('PrePay COD');

    const [triggerTime,setTriggereTime] = useState('0');
    const [codPaymentAppId,setCodPaymentAppId] = useState('')
    const [codPaymentAppSecret,setCodPaymentAppSecret] = useState('')
    const [error, setError] = useState(false);
    const [active, setActive] = useState(false);
    const [toastMsg,setToastMsg] = useState("Message sent")

    const { smUp } = useBreakpoints();

    const handlePaymentSelectChange = useCallback((value) => {
        let button = (value != codPrepaidData?.payment_gateway);
        setIsDisabled({...isDisabled, payment_selected_state: !button});
        setPaymentSelected(value);
    }, [payment_selected,codPrepaidData?.payment_gateway]);

    const handlePaymentAppIdChange = useCallback((value) => {
        let button = (value != codPrepaidData?.payment_gateway_app_id);
        setIsDisabled((prev) => ({ ...prev, payment_selected_state: !button }));
        setCodPaymentAppId(value)
    }, [codPaymentAppId,codPrepaidData?.payment_gateway_app_id]);

    const handleSelectChange = useCallback((value) => {
        let button = (value != codPrepaidData?.discount_type);
        setIsDisabled({...isDisabled, trigger_time_state: !button});
        setSelected(value)
    }, [selected,codPrepaidData?.discount_type]);

    const handleTriggerSelectChange = useCallback((value) => {
        let button = (value != codPrepaidData?.trigger_time);
        setIsDisabled({...isDisabled, trigger_time_state: !button});
        setTriggereTime(value)
    }, [triggerTime,codPrepaidData?.trigger_time]);
    const handleDiscountChange = useCallback((value) => {
        let button = (value != codPrepaidData?.discount_value);
        setIsDisabled({...isDisabled, trigger_time_state: !button});
        setDiscountValue(value)
    }, [discountValue,codPrepaidData?.discount_value]);

    const handleMinimumOrderValueChange = useCallback((value)=>{
        let button = (value != codPrepaidData?.minimum_order_value);
        setIsDisabled({...isDisabled, minimum_order_value_state: !button});
        setMinimumOrderValue(value)
    }, [minimumOrderValue,codPrepaidData?.minimum_order_value])

    const handlePrepaidOrderTagChange = useCallback((value)=>{
        let button = (value != codPrepaidData?.prepaid_order_tag);
        setIsDisabled({...isDisabled, prepaid_order_tag_status: !button});
        setPrepaidOrderTag(value)
    }, [prepaidOrderTag,codPrepaidData?.prepaid_order_tag])

    const handlePaymentAppSecretChange = useCallback((value) => {
        let button = (value != codPrepaidData?.payment_gateway_app_secret);
        setIsDisabled({...isDisabled, payment_selected_state: !button});
        setCodPaymentAppSecret(value)
    }, [codPaymentAppSecret,codPrepaidData?.payment_gateway_app_secret]);

    const handleLinkExpireSelectChange = useCallback((value)=>{
        let button = (value != codPrepaidData?.expire_time);
        setIsDisabled({...isDisabled, expire_time_state: !button});
        setExpireTime(value)
    }, [expireTime,codPrepaidData?.expire_time])
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const toastMarkup = active ? (<Toast content={toastMsg} onDismiss={toggleActive} error={error}/>) : null;

    const getCodPrepaidData = async ()=>{
        try {
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            let send_data = {
                 shop_info:shop_data.sdata.shop_info,
                 scid: shop_data?.sdata?.store_client_id
            };
            const response = await axios.post(apiEndpoint + `/api/get-wa-cod-prepaid-template`, send_data,{headers: headers})

            let response_data = response?.data?.data;
            setCodPrepaidData(response_data);
            let include = (response_data.is_include_discount == '1')
            setChecked(include)
            setDiscountValue(response_data.discount_value)
            setSelected(response_data.discount_type)
            setPaymentSelected(response_data.payment_gateway)

            setTriggereTime(response_data.trigger_time)

            setCodPaymentAppId(response_data.payment_gateway_app_id)
            setCodPaymentAppSecret(response_data.payment_gateway_app_secret)
            setExpireTime(response_data.expire_time)
            setMinimumOrderValue(response_data.minimum_order_value)
            setPrepaidOrderTag(response_data.prepaid_order_tag)

        } catch (error) {
            console.log("error=====catch=============>",error);
            setToastMsg("Something went wrong")
            setError(true)
            toggleActive()
        }
    }


    const handleSaveBtn = async ()=>{
        let button_status=true;
        try {
            setLoading({...loading, trigger_time_state: button_status});
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            console.log("selected==========================>",selected)
            let data = {
                is_include_discount:checked,
                discout_type:selected,
                discount_value:discountValue,
                template_id:codPrepaidData?.id,
                trigger_time:triggerTime
            }

            axios.post(apiEndpoint + `/api/save-wa-cod-prepaid-template`, {shop_info:shop_data.sdata.shop_info,data},{
                headers: headers
            }).then((response)=>{
                if(response.data.status == 200) {
                    setToastMsg("Successfully Saved")
                    setIsDisabled({...isDisabled, trigger_time_state: button_status});
                    setLoading({...loading, trigger_time_state: !button_status});
                    setCodPrepaidData({...codPrepaidData, discout_type: selected,trigger_time:triggerTime,discount_value:discountValue});
                    toggleActive()
                }else{
                    setToastMsg("Something went wrong");
                    setIsDisabled({...isDisabled, trigger_time_state: button_status});
                    setLoading({...loading, trigger_time_state: !button_status});
                    setError(true)
                    toggleActive()
                }
            }).catch( (err) => {
                setToastMsg("Something went wrong")
                setError(true)
                setIsDisabled({...isDisabled, trigger_time_state: button_status});
                setLoading({...loading, trigger_time_state: !button_status});
                toggleActive()
                console.log("err======================>",err)
            })
        } catch (error) {
            setToastMsg("Something went wrong")
            setError(true)
            toggleActive()
            setIsDisabled({...isDisabled, trigger_time_state: button_status});
            setLoading({...loading, trigger_time_state: !button_status});
            console.log("error=====catch=============>",error);
        }
    }
    const saveMinimumOrderValue = async ()=>{
        let button_status=true;
        try {

            setLoading({...loading, minimum_order_value_state: button_status});
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            let data = {
                shop_info: shop_data.sdata.shop_info,
                prepaid_id: codPrepaidData?.id,
                minimum_order_value: minimumOrderValue
            }

            axios.post(apiEndpoint + `/api/save-minimum-order-value`, data,{
                headers: headers
            }).then((response)=>{
                if(response.data.status == 200) {
                    setToastMsg("Successfully Saved");
                    setIsDisabled({...isDisabled, minimum_order_value_state: button_status});
                    setCodPrepaidData({...codPrepaidData, minimum_order_value: minimumOrderValue});
                    setLoading({...loading, minimum_order_value_state: !button_status});
                    toggleActive()
                }else{
                    setToastMsg("Something went wrong")
                    setError(true);
                    setIsDisabled({...isDisabled, minimum_order_value_state: button_status});
                    setLoading({...loading, minimum_order_value_state: !button_status});
                    toggleActive()
                }
            }).catch( (err) => {
                setToastMsg("Something went wrong")
                setError(true);
                toggleActive();
                setIsDisabled({...isDisabled, minimum_order_value_state: button_status});
                setLoading({...loading, minimum_order_value_state: !button_status});
                console.log("err======================>",err)
            })
        } catch (error) {
            setToastMsg("Something went wrong");
            setIsDisabled({...isDisabled, minimum_order_value_state: button_status});
            setLoading({...loading, minimum_order_value_state: !button_status});
            setError(true)
            toggleActive()
            console.log("error=====catch=============>",error);
        }
    }
    const handleExpireSaveBtn = async ()=>{
        let button_status=true;
        try {
            setLoading({...loading,expire_time_state:button_status})
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            let data = {
                shop_info: shop_data.sdata.shop_info,
                prepaid_id: codPrepaidData?.id,
                expire_time: expireTime
            }
            console.log("data==========handleSaveBtn============>",data)
            axios.post(apiEndpoint + `/api/save-expire-time`, data,{
                headers: headers
            }).then((response)=>{
                if(response.data.status == 200) {
                    setToastMsg("Successfully Saved")
                    setIsDisabled({...isDisabled, expire_time_state: button_status});
                    setCodPrepaidData({...codPrepaidData, expire_time: expireTime});
                    setLoading({...loading,expire_time_state:!button_status})
                    toggleActive()
                }else{
                    setToastMsg("Something went wrong")
                    setError(true)
                    setLoading({...loading,expire_time_state:!button_status});
                    setIsDisabled({...isDisabled, expire_time_state: button_status});
                    toggleActive()
                }
            }).catch( (err) => {
                setToastMsg("Something went wrong")
                setError(true)
                setLoading({...loading,expire_time_state:!button_status});
                setIsDisabled({...isDisabled, expire_time_state: button_status});
                toggleActive()
                console.log("err======================>",err)
            })
        } catch (error) {
            setToastMsg("Something went wrong")
            setLoading({...loading,expire_time_state:!button_status});
            setIsDisabled({...isDisabled, expire_time_state: button_status});
            setError(true)
            toggleActive()
            console.log("error=====catch=============>",error);
        }
    };

    const handleEnableBtn = ()=> {
        let button_status=true;
        setLoading({...loading, prepaid_enable_state: button_status});
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        let status = (codPrepaidData?.template_status == '0') ? '1' : '0'
        axios.post(apiEndpoint+ '/api/enable-wa-cod-prepaid',{shop_info: shop_data.sdata.shop_info,prepaid_id:codPrepaidData?.id,prepaid_status:status},{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved");
                setCodPrepaidData({...codPrepaidData, template_status: status});
                setLoading({...loading, prepaid_enable_state: !button_status});
                toggleActive();
            }else{
                setToastMsg("Something went wrong")
                setError(true);
                setLoading({...loading, prepaid_enable_state: !button_status});
            }
        }).catch((error)=>{
            setLoading({...loading, prepaid_enable_state: !button_status});
            setToastMsg("Something went wrong");
            setError(true);
            toggleActive();
        })
    }

    const savePrepaidOrderTag = async ()=>{
        let button_status=true;
        try {
            setLoading({...loading,prepaid_order_tag_status:button_status})
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            let data = {
                shop_info: shop_data.sdata.shop_info,
                prepaid_id: codPrepaidData?.id,
                prepaid_order_tag: prepaidOrderTag
            }
            axios.post(apiEndpoint + `/api/save-prepaid-order-tag`, data,{
                headers: headers
            }).then((response)=>{
                if(response.data.status == 200) {
                    setToastMsg("Successfully Saved");
                    setIsDisabled({...isDisabled, prepaid_order_tag_status: button_status});
                    setCodPrepaidData({...codPrepaidData, prepaid_order_tag: prepaidOrderTag});
                    setLoading({...loading,prepaid_order_tag_status:!button_status});
                    toggleActive();
                }else{
                    setToastMsg("Something went wrong")
                    setError(true)
                    setIsDisabled({...isDisabled, prepaid_order_tag_status: button_status});
                    setLoading({...loading,prepaid_order_tag_status:!button_status});
                    toggleActive();
                }
            }).catch((err) => {
                setToastMsg("Something went wrong")
                setError(true)
                setIsDisabled({...isDisabled, prepaid_order_tag_status: button_status});
                setLoading({...loading,prepaid_order_tag_status:!button_status});
                toggleActive()
            })
        } catch (error) {
            setToastMsg("Something went wrong")
            setIsDisabled({...isDisabled, prepaid_order_tag_status: button_status});
            setLoading({...loading,prepaid_order_tag_status:!button_status})
            setError(true)
            toggleActive()
        }
    }
    const savePaymentDetails = ()=> {
        let button_status=true
        setLoading({...loading, payment_selected_state: button_status});
        setToastMsg("Something went wrong");
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        let data = {
            shop_info: shop_data.sdata.shop_info,
            prepaid_id:codPrepaidData?.id,
            payment_gateway:payment_selected,
            payment_gateway_app_id:codPaymentAppId,
            payment_gateway_app_secret:codPaymentAppSecret
        }
        axios.post(apiEndpoint+ '/api/save-wa-cod-payment-details',data,{headers: headers}).then((response) => {
            if(response.data.status == 200) {

                setToastMsg("Successfully Saved")
                toggleActive();
                setIsDisabled({...isDisabled, payment_selected_state: button_status});
                setLoading({...loading,payment_selected_state: !button_status});
                setCodPrepaidData({...codPrepaidData, payment_gateway: payment_selected,payment_gateway_app_id: codPaymentAppId,payment_gateway_app_secret: codPaymentAppSecret});
            } else {

                setLoading({...loading,payment_selected_state: !button_status});
                setError(true);
                setIsDisabled({...isDisabled, payment_selected_state: button_status});
                toggleActive();
            }
        }).catch((error)=>{
            setLoading({...loading,payment_selected_state: !button_status});
            setIsDisabled({...isDisabled, payment_selected_state: button_status});
            setError(true);
            toggleActive();
        })
    };

    useEffect(() => {
        getCodPrepaidData();
    }, []);

    return (
        <>
            <Frame>
            <Page title="COD to PrePaid" divider fullWidth>
                <VerticalStack gap={{xs: "8", sm: "15"}}>
                    <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                        <Box
                            as="section"
                            paddingInlineStart={{xs: 4, sm: 0}}
                            paddingInlineEnd={{xs: 4, sm: 0}}
                        >
                            <VerticalStack gap="4">
                                <Text as="h3" variant="headingMd">
                                    COD to PrePaid Conversion
                                </Text>
                                <Text as="p" variant="bodyMd">
                                    Enable or disable the COD to PrePaid conversion setting.
                                </Text>
                            </VerticalStack>
                        </Box>
                        <AlphaCard roundedAbove="sm">
                            <div className="initsat-flex-align-conf">

                                <Text as="p" variant="bodyMd">
                                    COD to PrePaid is <Text as="span" fontWeight="bold">{(codPrepaidData?.template_status === '0') ? 'Disabled' : 'Enabled'}</Text> now
                                </Text>
                                {(codPrepaidData?.template_status === '0') ?  <Button primary  onClick={handleEnableBtn}>{(loading.prepaid_enable_state) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.payment_selected_state}/> : 'Enable'}</Button> :
                                    <Button destructive onClick={handleEnableBtn}>{(loading.prepaid_enable_state) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.payment_selected_state}/> : 'Disable'} </Button> }
                            </div>
                        </AlphaCard>
                    </HorizontalGrid>
                    {smUp ? <Divider/> : null}
                    <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                        <Box
                            as="section"
                            paddingInlineStart={{xs: 4, sm: 0}}
                            paddingInlineEnd={{xs: 4, sm: 0}}>
                            <VerticalStack gap="4">
                                <Text as="h3" variant="headingMd">
                                    Cash on Delivery (COD) to prepaid  Template
                                </Text>
                                <Text as="p" variant="bodyMd">
                                    Convert COD orders using WhatsApp. Message is sent immediately
                                    after a COD order is placed.
                                </Text>
                                <Text as="p" variant="bodyMd">
                                    Automated WhatsApp message templates cannot be modified or edited, because
                                    message templates need to be pre-approved by WhatsApp before they can be
                                    sent using the API.
                                </Text>
                            </VerticalStack>
                        </Box>
                        <AlphaCard roundedAbove="sm">
                            <VerticalStack gap="4">
                                <TextField label="COD Order to prepaid"
                                           value={codPrepaidData?.prepaid_message}
                                           disabled
                                           multiline={6}
                                           autoComplete="off"/>
                                <Select label="Choose when the message should be triggered" options={codPrepaidTriggerOptions} onChange={handleTriggerSelectChange} value={triggerTime}/>
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <Select
                                                label="Discount type"
                                                options={discountOptions}
                                                onChange={handleSelectChange}
                                                value={selected}
                                            />
                                            <TextField
                                                type="text"
                                                autoComplete="off" value={discountValue} onChange={handleDiscountChange} label="Discount value"/>
                                        </FormLayout.Group>
                                    </FormLayout>
                                <Button primary  onClick={handleSaveBtn} size="medium" loading={loading.trigger_time_state} disabled={isDisabled.trigger_time_state}> Save </Button>
                            </VerticalStack>
                        </AlphaCard>
                    </HorizontalGrid>
                    {paymentGatewayShow ?
                    <>
                    {smUp ? <Divider/> : null}
                    <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                        <Box
                            as="section"
                            paddingInlineStart={{xs: 4, sm: 0}}
                            paddingInlineEnd={{xs: 4, sm: 0}}>
                            <VerticalStack gap="4">
                                <Text as="h3" variant="headingMd">
                                    Payment Gateway - Razorpay/Cashfree
                                </Text>
                                <Text as="p" variant="bodyMd">
                                    To send payment links to your buyers for converting COD orders to prepaid, you need to link at least 1 payment gateway.
                                </Text>
                            </VerticalStack>
                        </Box>
                        <AlphaCard roundedAbove="sm">
                            <VerticalStack gap="4">
                                    <FormLayout>
                                            <Select label="Payment Gateway" options={paymentOptions} onChange={handlePaymentSelectChange} id="payment_selected_state" value={payment_selected}/>
                                        {(payment_selected == '0' || payment_selected == '1') ?
                                            <>
                                                <TextField type="text" autoComplete="off" value={codPaymentAppId} onChange={handlePaymentAppIdChange} label="Payment Gateway App ID"/>
                                                <TextField type="text" autoComplete="off" value={codPaymentAppSecret} onChange={handlePaymentAppSecretChange} label="Payment Gateway Secret"/>
                                            </>
                                          : <>
                                                <TextField type="text" autoComplete="off" value={codPaymentAppId} onChange={handlePaymentAppIdChange} label="Payment Gateway Key"/>
                                                <TextField type="text" autoComplete="off" value={codPaymentAppSecret} onChange={handlePaymentAppSecretChange} label="Payment Gateway Salt"/>
                                            </>}
                                    </FormLayout>
                                <Button primary  onClick={savePaymentDetails} size="medium" loading={loading.payment_selected_state}  disabled={isDisabled.payment_selected_state}> Save </Button>
                            </VerticalStack>
                        </AlphaCard>
                    </HorizontalGrid>
                    </> : null }
                    {smUp ? <Divider/> : null}
                    <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                        <Box
                            as="section"
                            paddingInlineStart={{xs: 4, sm: 0}}
                            paddingInlineEnd={{xs: 4, sm: 0}}>
                            <VerticalStack gap="4">
                                <Text as="h3" variant="headingMd">
                                    Link expires in
                                </Text>
                                <Text as="p" variant="bodyMd">
                                    Set the default expiry duration for all the payment links sent to your customers.
                                </Text>
                            </VerticalStack>
                        </Box>
                        <AlphaCard roundedAbove="sm">
                            <VerticalStack gap="4">
                                <FormLayout>
                                    <Select label="Link expires in" options={codPrepaidLinkExpireOptions} onChange={handleLinkExpireSelectChange} value={expireTime}/>

                                </FormLayout>
                                <Button primary  onClick={handleExpireSaveBtn} size="medium" loading={loading.expire_time_state} disabled={isDisabled.expire_time_state}> Save </Button>
                            </VerticalStack>
                        </AlphaCard>
                    </HorizontalGrid>
                    {smUp ? <Divider/> : null}
                    <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                        <Box
                            as="section"
                            paddingInlineStart={{xs: 4, sm: 0}}
                            paddingInlineEnd={{xs: 4, sm: 0}}>
                            <VerticalStack gap="4">
                                <Text as="h3" variant="headingMd">
                                    Minimum Order Value (Optional)
                                </Text>
                                <Text as="p" variant="bodyMd">
                                    Enter the minimum order value on which this coupon can be applied.
                                </Text>
                            </VerticalStack>
                        </Box>
                        <AlphaCard roundedAbove="sm">
                            <VerticalStack gap="4">


                                    <FormLayout>
                                        <FormLayout.Group>
                                            <TextField
                                                type="number"
                                                autoComplete="off" value={minimumOrderValue} onChange={handleMinimumOrderValueChange} label="Minimum Order Value"/>
                                        </FormLayout.Group>
                                    </FormLayout>
                                <Button primary  onClick={saveMinimumOrderValue} size="medium" loading={loading.minimum_order_value_state} disabled={isDisabled.minimum_order_value_state}> Save </Button>
                            </VerticalStack>
                        </AlphaCard>
                    </HorizontalGrid>

                    {smUp ? <Divider/> : null}
                    <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                        <Box
                            as="section"
                            paddingInlineStart={{xs: 4, sm: 0}}
                            paddingInlineEnd={{xs: 4, sm: 0}}>
                            <VerticalStack gap="4">
                                <Text as="h3" variant="headingMd">
                                    PrePaid COD Tag
                                </Text>
                                <Text as="p" variant="bodyMd">
                                    Automatically add tags to your orders based on converted COD to PrePaid order status.
                                </Text>
                            </VerticalStack>
                        </Box>
                        <AlphaCard roundedAbove="sm">
                            <VerticalStack gap="4">


                                <FormLayout>
                                    <FormLayout.Group>
                                        <TextField
                                            type="text"
                                            autoComplete="off" value={prepaidOrderTag} onChange={handlePrepaidOrderTagChange} label="Order Tag"/>
                                    </FormLayout.Group>
                                </FormLayout>
                                <Button primary  onClick={savePrepaidOrderTag} size="medium" loading={loading.prepaid_order_tag_status} disabled={isDisabled.prepaid_order_tag_status}> Save </Button>
                            </VerticalStack>
                        </AlphaCard>
                    </HorizontalGrid>
                </VerticalStack>
                {toastMarkup}
            </Page>
            </Frame>
        </>
    )
};

export default Codprepaid;