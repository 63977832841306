import React, { useEffect, useState} from "react"
import {
    Text,
    Layout,
    Page,
    VerticalStack,
    HorizontalGrid,
    Box, AlphaCard, LegacyCard, Badge
} from "@shopify/polaris";

import '../assets/style.css'
import axios from "axios";
import {apiEndpoint} from "../helper/config";
import {useSelector} from "react-redux";

const Pricing = () => {
    const shop_data = useSelector((state) => {
        return state.clientStore
    });

    const [payment_data,setPaymentData] = useState({});

    const getPaymentData = async (query="")=>{
        try {
            let headers = {
                'Content-Type': 'application/json',
                'Authentication': shop_data.sdata.authentication
            }
            const response = await axios.post(apiEndpoint + `/api/pricing_details`, {shop_info:shop_data.sdata.shop_info},{
                headers: headers
            }).catch( (err) => {
                console.log("err======================>",err)
            });
            console.log("response.data======================>",response.data)
            setPaymentData(response.data.data)

        } catch (error) {
            console.log("error=====catch=============>",error);
        }
    }

    useEffect(() => {

        getPaymentData("");
    }, []);




    return (
        <Page
            title="Pricing"
            divider
            fullWidth>
            <Layout>
                <Layout.Section>
                    <VerticalStack gap={{xs: "8", sm: "4"}}>
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Overview
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Account and Payments
                                    </Text>
                                </VerticalStack>
                            </Box>

                            <Layout.Section oneHalf>
                                <div className="initsat-flex-align initsat-card-change">





                                <LegacyCard title="Bill" >
                                    <LegacyCard.Section>

                                        <Text variant="heading3xl" as="h2">
                                            {`${payment_data?.pricing}USD`}
                                        </Text>

                                    </LegacyCard.Section>

                                </LegacyCard>
                                <LegacyCard title="Total Message Sent" >
                                    <LegacyCard.Section>

                                        <Text variant="heading3xl" as="h2">
                                            {(payment_data?.total_msg) ? payment_data?.total_msg :0}
                                        </Text>

                                    </LegacyCard.Section>
                                </LegacyCard>
                                    <LegacyCard title="Trial End Date" >
                                        <LegacyCard.Section>

                                            <Text variant="heading3xl" as="h2">
                                                {payment_data?.trial_date}
                                            </Text>

                                        </LegacyCard.Section>
                                    </LegacyCard>
                                </div>
                            </Layout.Section>

                        </HorizontalGrid>


                    </VerticalStack>
                </Layout.Section>

            </Layout>

        </Page>
    );
};

export default Pricing;