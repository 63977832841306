// eslint-disable-next-line no-unused-vars
import React from 'react';
import {createSlice} from '@reduxjs/toolkit'


const clientStoreSlices = createSlice({
    name:"shop_store",
    initialState: {sdata:{}},
    reducers:{
        addData: (state, action) => {
            console.log("action.payload===========================>",action.payload)
            state.sdata = action.payload
        },
        updateData:(state, action)=>{
            state.sdata.shop_info.app_status = action.payload
        },
        updateAppMode: (state, action)=>{
            state.sdata.shop_info.app_mode = action.payload
        },
        updateOnBoardingStatus: (state, action)=>{
            state.sdata.shop_info.onboarding_status = action.payload
        }

    }
})
export const {addData,updateData,updateAppMode,updateOnBoardingStatus} = clientStoreSlices.actions;
export default clientStoreSlices.reducer