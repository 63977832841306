import React from 'react';
import {configureStore} from "@reduxjs/toolkit";
import clientStoreSlices from "./slices/storeSlices";
import dateSlicesSlices from "./slices/dateSlices";

const store = configureStore({
    reducer:{
        clientStore:clientStoreSlices,
        dateRange:dateSlicesSlices
    }
})

export default store