import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import {apiEndpoint,shop_name} from "./helper/config";
import Router from "./router/Router";
import {useDispatch, useSelector} from "react-redux";
import {addData} from "./store/slices/storeSlices";
import OnBoarding from "./components/OnBoarding"
function App() {
    const shop_data = useSelector((state) => {
        return state.clientStore
    });
    const dispatch = useDispatch();
    const [shopData,setShopData] = useState()
    const StoreClientData = async () => {
        try {
            const response = await axios.post(apiEndpoint + `/cod-secure`, {shop: shop_name}).catch( (err) => {
                console.log("err======================>",err)
            });
            
            let shop_client_data = {
                'shop_info': response?.data?.data,
                'authentication': response?.headers?.security_token,
                'store_client_id': response.data?.data?.scid
            }
            setShopData(shop_client_data);
            dispatch(addData(shop_client_data));
        } catch (error) {
            console.log("error=====catch=============>",error);
        }
    }
    useEffect(() => {
        StoreClientData();
    }, []);


    if(shopData) {
        if(shop_data?.sdata?.shop_info?.onboarding_status == '3' || shop_data?.sdata?.shop_info?.charge_approve == 0) {
            return (<Router/>)
        } else {
            return (<OnBoarding/>)
        }
    } else{
        return('');
    }

}

export default App;