import React from 'react';
import {createSlice} from '@reduxjs/toolkit'


const dateRangeSlices = createSlice({
    name:"date_range",
    initialState: {date:{}},
    reducers:{
        addDateData: (state, action) => {
            console.log("action.payload===========date================>",action.payload)
            state.date = action.payload
        },
    }
})
export const {addDateData} = dateRangeSlices.actions;
export default dateRangeSlices.reducer