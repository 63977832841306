import React, {useState, useCallback, useEffect} from "react"
import {useSelector} from "react-redux";
import {
    AlphaCard,
    Box,
    HorizontalGrid,
    Page,
    Text,
    TextField,
    VerticalStack,
    Select,
    Checkbox, FormLayout, Button, Toast, Frame, Spinner, Divider, useBreakpoints
} from "@shopify/polaris";
import axios from "axios";
import {apiEndpoint} from "../helper/config";


const AbandonedCart = () => {
    const shop_data = useSelector((state) => {
        return state.clientStore
    });
    // const abandonedCartStatusOptions = [
    //     {label: 'Disable', value: '0'},
    //     {label: 'Enable', value: '1'}
    // ];

    const abandonedCartMsgTimeOptions = [
        {label: '15 Minutes', value: '15'},
        {label: '30 Minutes', value: '30'},
        {label: '45 Minutes', value: '45'}
    ]
    const discountOptions = [
        {label: 'Percentage%', value: '0'},
        {label: 'Fixed Amount', value: '1'}
    ];
    const whatsappWithoutDiscountMsg ='{{STORE_NAME}}: Hey {{CUSTOMER_NAME}}, It looks like you left some great items in your cart, still interested? Click here: {{URL}} ';
    const whatsappDiscountMsg ='{{STORE_NAME}}: Hey {{CUSTOMER_NAME}}! Still wondering if you should buy? Receive {{DISCOUNT_VALUE}} OFF\ndiscount with: "{{DISCOUNT_CODE}}" Buy Now: {{URL}}';

    let loading_button_state = {abandoned_enable_state:false,abandoned_data_state:false}
    let default_button_state = {abandoned_enable_state:true,abandoned_data_state:true}

    const [abdData, setAbdData] = useState({});
    const [abandonedCartStatus, setAbandonedCartStatus] = useState('0');
    const [AbandonedCartMsgTime, setAbandonedCartMsgTime] = useState('15');
    const [whatsappMsg,setWhatsappMsg] = useState('')
    const [loading, setLoading] = useState(loading_button_state);
    const [isDisabled, setDisabled] = useState(default_button_state);

    const [isDiscountChecked, setDiscountChecked] = useState(false);
    const [discountType, setDiscountType] = useState('0');
    const [discountValue,setDiscountValue] = useState('');

    const [error, setError] = useState(false);
    const [active, setActive] = useState(false);
    const [toastMsg,setToastMsg] = useState("Message sent")
    const { smUp } = useBreakpoints();

    const handleDiscountTypeChange = useCallback((value) => {
        let button = (value != abdData?.discount_type);
        setDisabled({...isDisabled, abandoned_data_state: !button});
        setDiscountType(value)
    }, [abdData?.discount_type]);
    const handleDiscountValueChange= useCallback((value) => {
        let button = (value != abdData?.discount_value);
        setDisabled({...isDisabled, abandoned_data_state: !button});
        setDiscountValue(value)
    }, [abdData?.discount_value]);

    // const handleAbandonedCartStatusChange = useCallback((value) => {
    //         let button = (value != abdData?.template_status);
    //         setDisabled({...isDisabled, abandoned_enable_state: !button});
    //
    //         setAbandonedCartStatus(value)
    //     }, [abdData?.template_status]);
    const handleAbandonedCartMsgTimeChange = useCallback((value) => {
            let button = (value != abdData?.wa_msg_sent_time);
        setDisabled({...isDisabled, abandoned_data_state: !button});
            setAbandonedCartMsgTime(value)
        }, [abdData?.wa_msg_sent_time]);

    const handleChange = useCallback((newChecked) => {
        let is_discount = (abdData?.is_include_discount == '0') ? false : true;
        let button = (newChecked != is_discount);
        if(newChecked){
            setWhatsappMsg(whatsappDiscountMsg)
        } else{
            setWhatsappMsg(whatsappWithoutDiscountMsg)
        }
        setDisabled({...isDisabled, abandoned_data_state: !button});
        return setDiscountChecked(newChecked)
    }, [abdData?.is_include_discount]);

    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const toastMarkup = active ? (<Toast content={toastMsg} onDismiss={toggleActive} error={error}/>) : null;

    const handleEnableBtn = ()=> {
        let button_status=true;
        setLoading({...loading, abandoned_enable_state: button_status});

        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        let status = (abdData.template_status == '0') ? '1' : '0';
        let data = {
            adc_id:abdData?.id,
            abandoned_cart_status:status
        };
        axios.post(apiEndpoint+ '/api/enable-abandoned-cart',{shop_info: shop_data.sdata.shop_info,data},{
            headers: headers
        }).then((response) => {
            console.log("response.data===========================>",response.data)
            if(response.data.status == 200) {
                setToastMsg("Successfully Saved");
                setAbdData({...abdData, template_status: status});
                setLoading({...loading, abandoned_enable_state: !button_status});

                toggleActive();
            }else{
                setToastMsg("Something went wrong")
                setError(true);
                setLoading({...loading, abandoned_enable_state: !button_status});

            }
        }).catch((error)=>{
            setLoading({...loading, abandoned_enable_state: !button_status});

            setToastMsg("Something went wrong");
            setError(true);
            toggleActive();
        })
    }

    const handleSaveData = () => {
        let button_status=true;
        setLoading({...loading, abandoned_data_state: button_status});

        let data = {adc_id:abdData?.id,
                    abandoned_cart_msg_time:AbandonedCartMsgTime,
                    is_discount_enabled:isDiscountChecked,
                    discount_type:discountType,
                    discount_value:discountValue};

        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint + '/api/save-abandoned-cart', {shop_info: shop_data?.sdata?.shop_info,data},{
            headers: headers
        }).then((response) => {

            if (response.data.status == 200) {
                setToastMsg("Successfully Saved");
                let discount_checked=(isDiscountChecked) ? '1' : '0';
                setAbdData({...abdData,template_status:abandonedCartStatus,wa_msg_sent_time:AbandonedCartMsgTime,is_include_discount:discount_checked,discount_type:discountType,discount_value:discountValue});
                setLoading({...loading, abandoned_data_state: !button_status});
                setDisabled({...isDisabled, abandoned_data_state: button_status});
            } else{
                setToastMsg("Something went wrong")
                setError(true)
                setLoading({...loading, abandoned_data_state: !button_status});
                setDisabled({...isDisabled, abandoned_data_state: button_status});
            }
        }).catch((error) => {
            setToastMsg("Something went wrong")
            setLoading({...loading, abandoned_data_state: !button_status});
            setDisabled({...isDisabled, abandoned_data_state: button_status});
            setError(true)
        })
    }

    const getGeneralSettings = ()=>{

        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/get-abandoned-cart-settings',{shop_info: shop_data.sdata.shop_info},{
            headers: headers
        }).then((response) => {

            if(response?.data?.status == 200) {
                let res_data = response.data.data;
                let is_discount = (res_data?.is_include_discount == '0') ? false : true;

                let wa_massage = (!is_discount) ? res_data?.whatsapp_without_discount_msg :res_data?.whatsapp_msg


                setAbandonedCartStatus(res_data?.template_status)
                setWhatsappMsg(wa_massage);

                setAbandonedCartMsgTime(res_data?.wa_msg_sent_time)
                setDiscountChecked(is_discount)
                setDiscountType(res_data?.discount_type)
                setDiscountValue(res_data?.discount_value)
                setAbdData(res_data)

            } else{
                setToastMsg("Something went wrong")
                setError(true)
                toggleActive()
            }

        }).catch((error)=>{
            console.log("error==================================>",error)
            setToastMsg("Something went wrong")
            setError(true)
            toggleActive()
        })
    }
    useEffect(() => {
        getGeneralSettings();
    }, []);

    return (
        <>
            <Frame>
            <Page title="Abandoned Cart" divider fullWidth>
                <VerticalStack gap={{xs: "8", sm: "15"}}>

                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 0}}
                                paddingInlineEnd={{xs: 4, sm: 0}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Abandoned Cart Recovery Conversion
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Enable or disable the Abandoned Cart Recovery conversion setting.
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <div className="initsat-flex-align-conf">

                                    <Text as="p" variant="bodyMd">
                                        Abandoned Cart Recovery is <Text as="span" fontWeight="bold">{(abdData.template_status === '0') ? 'Disabled' : 'Enabled'}</Text> now
                                    </Text>
                                    {(abdData.template_status === '0') ?  <Button primary  onClick={handleEnableBtn}>{(loading.abandoned_enable_state) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.abandoned_enable_state}/> : 'Enable'}</Button> :
                                        <Button destructive onClick={handleEnableBtn}>{(loading.abandoned_enable_state) ? <Spinner accessibilityLabel="Small spinner example" size="small" disabled={isDisabled.abandoned_enable_state}/> : 'Disable'} </Button> }
                                </div>
                            </AlphaCard>
                        </HorizontalGrid>
                        {smUp ? <Divider/> : null}
                        <HorizontalGrid columns={{xs: "1fr", md: "2fr 5fr"}} gap="4">
                            <Box
                                as="section"
                                paddingInlineStart={{xs: 4, sm: 4}}
                                paddingInlineEnd={{xs: 4, sm: 4}}
                            >
                                <VerticalStack gap="4">
                                    <Text as="h3" variant="headingMd">
                                        Abandoned Recovery Message
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Recover abandoned carts by reaching your customers on WhatsApp.
                                    </Text>
                                    <Text as="p" variant="bodyMd">
                                        Automated WhatsApp message templates cannot be modified or edited, because
                                        message
                                        templates need to be pre-approved by WhatsApp before they can be sent using the
                                        API.
                                    </Text>
                                </VerticalStack>
                            </Box>
                            <AlphaCard roundedAbove="sm">
                                <VerticalStack gap="4">
                                    {/*<Select*/}
                                    {/*    label="Status"*/}
                                    {/*    options={abandonedCartStatusOptions}*/}
                                    {/*    onChange={handleAbandonedCartStatusChange}*/}
                                    {/*    value={abandonedCartStatus}*/}
                                    {/*/>*/}
                                    <VerticalStack gap="4">
                                        <TextField label="WhatsApp Template" multiline={4} autoComplete="off" disabled
                                                   value={whatsappMsg}/>
                                    </VerticalStack>
                                    <Select
                                        label="Send WhatsApp message after"
                                        options={abandonedCartMsgTimeOptions}
                                        onChange={handleAbandonedCartMsgTimeChange}
                                        value={AbandonedCartMsgTime}
                                    />


                                    <Checkbox
                                        label="Include Discount"
                                        checked={isDiscountChecked}
                                        onChange={handleChange}
                                    />
                                    {(isDiscountChecked) ?
                                        <FormLayout>
                                            <FormLayout.Group>
                                                <Select
                                                    label="Discount type"
                                                    options={discountOptions}
                                                    onChange={handleDiscountTypeChange}
                                                    value={discountType}
                                                />
                                                <TextField
                                                    type="text"
                                                    autoComplete="off" value={discountValue} onChange={handleDiscountValueChange} label="Discount value"/>
                                            </FormLayout.Group>
                                        </FormLayout> : ''}
                                    <Button primary onClick={handleSaveData} className={"btn-me-work"} disabled={isDisabled.abandoned_data_state} loading={loading.abandoned_data_state}>Save</Button>
                                </VerticalStack>

                            </AlphaCard>
                        </HorizontalGrid>
                </VerticalStack>
                {toastMarkup}
            </Page>
            </Frame>
        </>
    )
}
export default AbandonedCart;