let shop_name;
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

shop_name = params?.shop ? params?.shop : '';
const host = params?.host;

const HOSTNAME = window.location.hostname;

let apiEndpoint = `https://cod-secure.test/services`;
let config = {
    apiKey: `b3830dd91407fda6255699e813d6f7b3`,
    forceRedirect: true,
    host: host
}
console.log("hostname=====================>",HOSTNAME)
if(HOSTNAME == 'cod-secure.test'){
    apiEndpoint = `https://cod-secure.test/services`;
    config['apiKey'] = 'b3830dd91407fda6255699e813d6f7b3'
} else if(HOSTNAME == 'promobar.inittasker.com'){
    apiEndpoint = `https://promobar.inittasker.com/services`;
    config['apiKey'] = '34ba061be690ff6470246dcc5249e635'
} else if(HOSTNAME == 'codverify.initsat.com'){
    apiEndpoint = `https://codverify.initsat.com/services`;
    config['apiKey'] = 'e4d7ad3690cdad9e788422fa7dfb6636'
} else if(HOSTNAME == 'codsecure.initsat.com'){
    apiEndpoint = `https://codsecure.initsat.com/services`;
    config['apiKey'] = '4dece3731b0a6959dc9098d1c1f5a893'
}

export {
    config,
    shop_name,
    host,
    apiEndpoint
};