import React from "react";
import { Routes, Route} from "react-router-dom";

import Orders from '../pages/Orders';
import Codprepaid from '../pages/Codprepaid';
import Overview from "../pages/Overview";
import Configure from "../pages/Configure";
import AbandonedCart from "../pages/AbandonedCart"
import AbandonedCartLogs from "../pages/AbandonedCartLogs";
import Pricing from "../pages/Pricing"
import Charge from "../components/Charge"

const Routeslink = () => {
    return (
        <>
            <Routes>
                <Route path="/overview" index element={<Overview/>}/>
                <Route path="/configure" element={<Configure/>}/>
                <Route path="/cod-to-prepaid" element={<Codprepaid/>}/>
                <Route path="/order-logs" element={<Orders/>}/>
                <Route path="/abandoned-cart" element={<AbandonedCart/>}/>
                <Route path="/abandoned-cart-logs" element={<AbandonedCartLogs/>}/>
                <Route path="/charge" element={<Charge/>}/>
                <Route path="/pricing" element={<Pricing/>}/>
            </Routes>
        </>
    )
}
export default Routeslink