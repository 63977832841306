import {NavMenu} from '@shopify/app-bridge-react';
import { BrowserRouter, Link} from "react-router-dom";
import Routeslink from "./Routeslink";
import React from "react";

function Router() {
    return (
        <>
            <BrowserRouter>
                <NavMenu>
                    <Link to="/overview" rel="home">Overview</Link>
                    <Link to="/configure">Configure</Link>
                    <Link to="/cod-to-prepaid">COD to prepaid</Link>
                    <Link to="/order-logs">Order logs</Link>
                    <Link to="/abandoned-cart">Abandoned cart</Link>
                    <Link to="/abandoned-cart-logs">Abandoned cart logs</Link>
                    <Link to="/pricing">Pricing</Link>
                </NavMenu>
               <Routeslink/>
            </BrowserRouter>
        </>
    );
}

export default Router