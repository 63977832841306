import React from 'react';
import createApp from '@shopify/app-bridge';
// import { Context } from '@shopify/app-bridge-react';
import {Redirect} from '@shopify/app-bridge/actions';
import {config} from "../helper/config";

function Charge() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    console.log("params===============================>", params)
    console.log("config===============================>", config)
    let charge_url = params?.charge_url ? params?.charge_url :'';
    // const app = useContext(Context);
    const app = createApp(config);

    //const redirect = Redirect.create(app);
    console.log("charge_url===============================>", charge_url)
    app.dispatch(Redirect.toRemote({url:charge_url}));

}

export default Charge;