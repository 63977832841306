
import React, {useState} from "react";
import '../../assets/style.css'
import axios from "axios";
import {apiEndpoint} from "../../helper/config";
import {updateAppMode} from "../../store/slices/storeSlices";
import {useDispatch, useSelector} from "react-redux";
import { ReactComponent as Whatsapp } from "../../assets/image/whatsapp.svg"


export default function Verification({setToastMsg,setError,toggleActive,getGeneralSettings}) {
    const dispatch = useDispatch();
    const shop_data = useSelector((state) => {
        return state.clientStore
    });
    const [appMode, setAppMode] = useState(shop_data?.sdata?.shop_info?.app_mode);

    console.log("shop_data=======Verification===0==========>",appMode)
    let app_mode_list = {
        "wasmstype": '0',
        "calltype": '1',
        "callwasmstype": '2'
    }
    const handleAppModeChange=(value) => {
        let data = {app_mode:value};
        let headers = {
            'Content-Type': 'application/json',
            'Authentication': shop_data?.sdata?.authentication
        }
        axios.post(apiEndpoint+ '/api/change-app-mode',{shop_info: shop_data.sdata.shop_info,data},{
            headers: headers
        }).then((response) => {
            if(response?.data?.status == 200) {
                setToastMsg("Changed app mode successfully")

                setAppMode(app_mode_list[value])
                dispatch(updateAppMode(app_mode_list[value]));
                getGeneralSettings();
            }else{
                setToastMsg("Something went wrong")
                setError(true)

            }
        }).catch((error)=>{
            setToastMsg("Something went wrong")
            setError(true)
        }).finally(()=>{
            toggleActive()
        })
    }

    return (

            <div className="section-content">
                <div className="section-row">
                    <div id="calltypemode" className="initsat-all" style={{width:'100%'}}>
                        {/*
                        <div className="initsat-section-cell highlight" onClick={() => handleAppModeChange('calltype')} id="spcalltype">
                            {appMode == '1' ? <div className="initsat-active-label">Active</div> :''}
                            <input className="apptype " name="appmode" id="calltype" type="radio"
                                   value="calltype" style={{display:'none'}}/>

                                <div className="initsat-icon-size"><i className="fa fa-phone" aria-hidden="true"></i></div>

                                <div style={{fontSize:15,fontWeight: 'bold',float:'left',marginBottom: 10,marginTop: 10,clear:'both'}}>Voice Call Mode</div>
                                <br/>
                                <div style={{float: 'left'}}>Confirm COD orders only by voice call</div>

                        </div> */}

                        <div className="initsat-section-cell" onClick={() => handleAppModeChange('wasmstype')}
                             id="wpsmstype">
                            {appMode == '0' ? <div className="initsat-active-label">Active</div> :''}
                            <input className="apptype " name="appmode" id="smstype" type="radio" value="smstype"
                                   checked="checked" style={{display:"none"}}/>

                                <div className="initsat-icon-size">
                                    <Whatsapp/>
                                </div>

                                <div
                                    style={{"fontSize":15,"fontWeight": "bold","float":"left","marginBottom": 10,"marginTop": 10,"clear":"both"}}>WhatsApp
                                    Mode
                                </div>
                                <br/>
                                    <div style={{float: "left"}}>Confirm COD orders only by WhatsApp</div>

                        </div>
                        {/*
                        <div className="initsat-section-cell" onClick={() => handleAppModeChange('callwasmstype')} id="callwpsmstype">
                            {appMode == '2' ? <div className="initsat-active-label">Active</div> :''}
                            <input className="apptype " name="appmode" id="callsmstype" type="radio"
                                   value="callsmstype" style={{display:"none"}}/>

                                <div className="initsat-icon-size"><i className="mes"></i><i className="fa fa-whatsapp"></i>
                                </div>

                                <div
                                    style={{"font-size":15,"font-weight": "bold","float":"left","margin-bottom": 10,"margin-top": 10,"clear":"both"}}>Call &amp; WhatsApp
                                    Mode
                                </div>
                                <br/>
                                    <div style={{float: "left"}}>Confirm orders by call or WhatsApp</div>

                        </div>*/}
                    </div>
                </div>
            </div>
    );
}